<!-- As a heading -->
<div class="authentixBackgroundColor filter-role top-title-bar">
  <a class="adjustments">Add Sample</a>
</div>



<form #myform="ngForm" ngNativeValidate (ngSubmit)="register(myform)">

  <div class="gray-bg mt-1">
  <div class="gray-bg mt-1">
    <div class="gray-box-head">
      <h2>General Information</h2>
    </div>

  <!-- Row 1 -->
  <div class="form-row">
    <div class="form-group col-md-3">
      <label for="sample-serial">Sample</label>
      <input name="serial" id="sample-serial"  class="form-control" ngModel required>
    </div>

    <div class="form-group col-md-3">
      <label for="sample-description">Description</label>
      <input name="sampleDescription" id="sample-description"  class="form-control" ngModel required>
    </div>

    <div class="form-group col-md-3">
      <label for="sample-type">Type</label>
      <input name="sampleType" id="sample-type"  class="form-control" ngModel required>
    </div>

    <div class="form-group col-md-3">
      <label for="sample-source">Source</label>
      <input name="sampleSource" id="sample-source"  class="form-control" ngModel required>
    </div>
  </div>

  <!-- Row 2 -->
  <div class="form-row">
    <div class="form-group col-md-3">
      <label>Sample Date</label>
      <input  name="sampleDate" ngModel class="form-control" type="date">
    </div>

    <div class="form-group col-md-3">
      <label>Report Date</label>
      <input  name="reportDate" ngModel class="form-control" type="date">
    </div>

    <div class="form-group col-md-3">
      <label>Received Date</label>
      <input   name="sampleReceivedDate" ngModel class="form-control" type="date">
    </div>

    <div class="form-group col-md-3">
      <label>Analyzed Date</label>
      <input  name="sampleAnalyzedDate" ngModel class="form-control" type="date">
    </div>
  </div>

  <!-- Row 3 -->
  <div class="form-row">
    <div class="form-group col-md-3">
      <label>Location</label>
      <input  name="sampleLocation" type="text" ngModel class="form-control" >
    </div>

    <div class="form-group col-md-3">
      <label>Client Id</label>
      <input  name="clientId" type="text" ngModel class="form-control" >
    </div>

    <div class="form-group col-md-3">
      <label>Sampled By</label>
      <input  name="sampledBy" ngModel class="form-control" >
    </div>
  </div>
</div>

<!-- Editable table -->
    <div class="gray-bg mt-1">
      <div class="gray-box-head">
      <span class="table-add float-right">
        <a class="text-success" (click)="add()">
          <mdb-icon fas icon="plus" size="2x"></mdb-icon>
        </a>
      </span>

        <h2>Properties</h2>

      </div>

      <div class="card-body ">
        <div id="table" class=" shadow-lg table-editable ">
      <table mdbTableScroll class="table table-bordered table-responsive-md table-striped text-center" scrollY="true" maxHeight="300" >
        <tr>
          <th style="text-align: left">Property</th>
          <th class="text-center">Value</th>
          <th class="text-center">Method Used</th>
          <th class="text-center">Units</th>
          <th class="text-center">Remove</th>
        </tr>
        <tr *ngFor="let property of propertyList; let id = index">
          <td style="text-align: left; padding:10px">
            <span  (keyup)="changeValue(id, 'key', $event)" (blur)="updateList(id, 'key', $event)" contenteditable="true">{{property.key}}</span>
          </td>
          <td style="padding: 10px">
            <span (keyup)="changeValue(id, 'value', $event)" (blur)="updateList(id, 'value', $event)" contenteditable="true">{{property.value}}</span>
          </td>
          <td style="padding: 10px">
            <span contenteditable="true" (keyup)="changeValue(id, 'methodUsed', $event)" (blur)="updateList(id, 'methodUsed', $event)">{{property.methodUsed}}</span>
          </td>
          <td style="padding: 10px">
            <span contenteditable="true" (keyup)="changeValue(id, 'units', $event)" (blur)="updateList(id, 'units', $event)">{{property.units}}</span>
          </td>
          <td style="padding: 10px">
            <span class="table-remove">
              <button type="button" mdbBtn color="danger" rounded="true" size="sm" class="my-0" style="max-height: 15px; line-height: 0; padding: 10px !important; border-radius: 0.25rem;" (click)="remove(id)">Remove</button>
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<!-- Editable table -->

    <div class="gray-bg mt-1">
      <div class="gray-box-head">
<button type="submit" value="Register" class="btn-ornge buttonFix float-right">Submit</button>
<button routerLink="/ASTM/MOE" class=" btn-gray buttonFixCancel">Cancel</button>
      </div>
    </div>

  </div>

</form>
