import {Component, OnInit, ViewChild} from '@angular/core';
import * as XLSX from 'xlsx';
import {HttpClient} from '@angular/common/http';
import { FileSystemFileEntry } from 'ngx-file-drop';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute} from "@angular/router";
import {DataService} from "../../dataServiceFSMSites/data.service";
import {AddSiteInterface, SitesSupplementalInfo} from "../../interfaceModels/addSiteInterface";

@Component({
  selector: 'app-bulk-site-add',
  templateUrl: './bulk-site-add.component.html',
  styleUrls: ['./bulk-site-add.component.scss']
})
export class BulkSiteAddComponent implements OnInit {

  constructor(private http: HttpClient, public dataService: DataService, private activatedRoute: ActivatedRoute) { }

  files: any = [];
  arrayBuffer: any;
  file: File;
  headElements = [];
  sitesToUpload: any = [];
  verifiedSites: any = [];
  programConfig: any[] = new Array;
  headers: any = [];
  bulkSites: any[] = [];
  tempSite: AddSiteInterface;
  public dataSource = new MatTableDataSource<any>();
  stuff: any;
  private customer: string;
  dbSturcture: any = [];
  fileName: string = "";
  public ColumnsList: string[];
  displayNoRecords: boolean = true;
  allowSubmit:  boolean = true;
  previewMessage: string =  '';
  perfectRecords: number = 0;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.customer = this.activatedRoute.snapshot.paramMap.get('customer');

    if(!this.dataService.bulkUploadFields.includes('Remarks'))
    this.dataService._bulkUploadFields.push('Remarks');

    this.allowSubmit = true;

    this.dataService.getVisuals(this.customer).subscribe((data: any) => {

     // rename stuff

      this.stuff = data;

      for (let prop in this.stuff){
        if (this.stuff[prop] !== 'Hidden' && prop !== 'Supplemental')
        {

          if(prop === 'OilMarketingCompany')
            prop = 'Oil Marketing Company';

          this.headers.push({[prop]: null});
          this.headElements.push(prop);
        }
      }



      for (const prop in this.stuff.Supplemental){
        if (this.stuff.Supplemental[prop].Policy !== 'Hidden' )
        {
          this.headers.push({[this.stuff.Supplemental[prop].Name]: null});
          this.headElements.push(this.stuff.Supplemental[prop].Name);
        }
      }

    });

    console.log(this.dataService.siteFields);
    console.log(this.dataService.bulkUploadFields);

  }

  uploadFile(event) {
    var fileExtension = event[0].name.split('.').pop();
    if (fileExtension !== "xls" && fileExtension !== "xlsx")
      console.log("Wrong File Type");

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.convertExcelToSiteList(event);
      this.files.push(element.name);
      this.fileName = element.name;


    }
    this.allowSubmit = false;
  }

  generateXcelFile(){
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.headers);
    const wscols = [];

    for (const elem of this.headElements){
      wscols.push({ wch: elem.length + 5});
    }
    workSheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    return XLSX.writeFile(workBook, 'template.xlsx'); // initiate a file download in browser
  }

  convertExcelToSiteList(event){
    this.file = event[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.sitesToUpload = XLSX.utils.sheet_to_json(worksheet, {raw: true});
    };

  }

  validateFile(){
    this.displayNoRecords = false;

    for (let index = 0; index < this.sitesToUpload.length; index++) {

      if(this.sitesToUpload[index].Name === undefined){
        this.sitesToUpload[index].message = 'Missing site name';
      }

      if(this.sitesToUpload[index].Code === undefined){
        this.sitesToUpload[index].message = 'Missing site code';
      }

      console.log(this.dataService.currentSites);

      this.dataService.currentSites.filter = this.sitesToUpload[index].Code;

      if(this.dataService.currentSites.filteredData.length > 0){
        this.sitesToUpload[index].message = 'Site code ' + this.sitesToUpload[index].Code + ' already exists';
      }

      if(this.sitesToUpload[index].Name === undefined && this.sitesToUpload[index].Code === undefined){
        this.sitesToUpload[index].message = 'Missing site name | Missing site code';
      }

      if(this.sitesToUpload[index].message === undefined) {
        this.sitesToUpload[index].message = '';
        this.perfectRecords++;
        this.verifiedSites.push(this.sitesToUpload[index]);
      }
    }

    this.dataSource = this.sitesToUpload;

    if(this.perfectRecords !== 0)
      this.previewMessage = this.perfectRecords + " Record(s) can be successfully uploaded out of " + this.sitesToUpload.length + ". Errors can be viewed by hovering over Failed in the \"Remarks\" section";
    else
      this.previewMessage = this.perfectRecords + " Record(s) can be successfully uploaded out of " + this.sitesToUpload.length + ".";


  }

  clearData(){
    this.sitesToUpload = [];
    this.dataSource = null;
    this.fileName = null;
    this.previewMessage = '';
  }

  prepareSiteData(){


    for (let index = 0; index < this.verifiedSites.length; index++) {

      const tempsite = new AddSiteInterface();

      //Copy like properties
      for (const prop in tempsite){
        tempsite[prop] = this.verifiedSites[index][prop];

        if(prop === 'OilMarketingCompany')
          tempsite[prop] = this.verifiedSites[index]['Oil Marketing Company'];

        if(prop === 'Address' && this.dataService.bulkUploadFields.includes('Suburb'))
          tempsite[prop] = this.verifiedSites[index]['Suburb'];

      }

      tempsite.SitesSupplementalInfo = [];

      for (const field in this.dataService.supplementalFields){
        let tempSup = new SitesSupplementalInfo();
        tempSup.Key = this.dataService.supplementalFields[field];
        tempSup.Value = this.verifiedSites[index][this.dataService.supplementalFields[field]];
        tempsite.SitesSupplementalInfo.push(tempSup);
      }

      this.bulkSites.push(tempsite);
    }
    console.log(this.bulkSites);
    this.dataService.bulkAddSites(this.bulkSites, this.customer);
    this.verifiedSites = [];
  }


}

