import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatePipe} from '@angular/common';
import {ListObjects} from '../../interfaceModels/listObjects';
import {ModalDirective} from 'ng-uikit-pro-standard';
import {DataService} from '../../dataServiceZambiASTM/data.service';

@Component({
  selector: 'app-edit-sample',
  templateUrl: './zambia-astm-edit-sample.component.html',
  styleUrls: ['./zambia-astm-edit-sample.component.scss']
})
export class ZambiaAstmEditSampleComponent implements OnInit{

  editField: string;
  editableSample: ListObjects;

  onOpen(event: any) {
    console.log(event);
  }

  @ViewChild('basicModal') public showModalOnClick: ModalDirective;

  constructor(public dataService: DataService, public spinner: NgxSpinnerService, public datepipe: DatePipe) { }

  onSubmit() {
    console.log(this.editableSample);

    if (this.editableSample.suspect === true)
    {
      this.showModalOnClick.show();
    }
    else {
    this.dataService.updateSample(this.editableSample);
    }
  }

  verifySave() {
    this.editableSample.suspect = false;

    for (let i = 0; i <= this.editableSample.formData.length; i++){

      if (this.editableSample.formData[i] !== undefined) {
        this.editableSample.formData[i].suspect = false;
      }
    }

    this.dataService.updateSample(this.editableSample);
    this.showModalOnClick.hide();
  }

  dontVerifySave() {
    this.dataService.updateSample(this.editableSample);
    this.showModalOnClick.hide();
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.editableSample.formData[id][property] = editField;
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.dataService.getProperties(this.dataService.sample.id).subscribe((data: any) => {

      console.log(data.response);

      this.editableSample = data.response;

      this.editableSample.formData = data.response.formData;

      for (let i = 0; i <= this.editableSample.formData.length; i++ )
       {

         if (this.editableSample.formData[i] !== undefined){
           if (this.editableSample.formData[i].value === null){
             this.editableSample.formData[i].value = 'n/a';
           }
         }

       }

      if (this.editableSample.dateSampled !== null) {
      this.editableSample.dateSampled =  this.editableSample.dateSampled.slice(0, this.editableSample.dateSampled.length - 9);
      }

      if (this.editableSample.reportDate !== null) {
      this.editableSample.reportDate = this.editableSample.reportDate.slice(0, this.editableSample.reportDate.length - 9);
      }

      if (this.editableSample.dateReceived !== null) {
        this.editableSample.dateReceived = this.editableSample.dateReceived.slice(0, this.editableSample.dateReceived.length - 9);
      }


      this.spinner.hide();
    });
  }

}
