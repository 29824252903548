<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
<!--Header For Page-->


<div mdbModal #basicModal="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Duplicate Sites Detected</p>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <mdb-icon fas icon="coins" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
          <p>This program has {{this.duplicateCount}} duplicate sites. Would you like to consolidate those records?</p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">

        <a type="button" class="buttonFixCancel btn-gray waves-light " (click)="dontVerifySites()" mdbWavesEffect
           data-dismiss="modal">No, Thanks</a>

        <a class="waves-effect btn-ornge buttonFix " style=" color: white; margin-left: 50% !important;" mdbWavesEffect [routerLink]="['DuplicateSiteWizard/']" >Verify Sites
        </a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div class="top-title-bar" style="position: sticky !important; top: 0px; z-index: 3">
  <h2 class="page-title">{{customer}} Site Management</h2>
  <div class="filter-role col-sm text-right p-none">

    <a class="btn btn-defult site-btn" [routerLink]="['Users/']" >User Management</a>
    <a (click)="toggleMapOff()" style="cursor: pointer;color: white" class="btn btn-defult site-btn"> {{toggleName}} View</a>
    <input type="search" placeholder="Any Keyword" autocomplete="off" name="search"
           (keyup)="applyFilter($event)" class="text-box text-search">
    <a routerLink="bulkUploadSites" style="cursor: pointer;color: white" class="btn btn-defult site-btn">
      <i class="fa fa-plus"></i> Bulk Upload</a>
  </div>
</div>

<!--Main Body-->

<div class=" col-lg-12 white-box" style="margin: 0px !important; max-height: 850px !important;">

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium"  color="#dd7322" type="ball-spin-clockwise-fade">
  </ngx-spinner>

<!--  Map of all sites-->
  <div class="row" >
    <div *ngIf="showHalfMap" [ngClass]="this.mapCss" [ngStyle]="this.mapSizing">
      <agm-map [latitude]="mapLat" [longitude]="mapLong" [zoom]="zoom"
               style="width: 99%; height: 99%; position: absolute">
        <span *ngFor="let item of siteList;">
        <agm-marker *ngIf="item.Geolocation !== null" (mouseOver)="window.open()" (mouseOut)="window.close()"
                    [latitude]="item.Geolocation.Latitude" [longitude]="item.Geolocation.Longitude"
                    (markerClick)="populate(item)">
          <agm-info-window #window>
            <h6>{{item.Name}}</h6>
          </agm-info-window>
        </agm-marker>
        </span>
      </agm-map>
    </div>


<!--    Map with selected site-->
    <div *ngIf="addLocation" [ngClass]="this.mapCss" [ngStyle]="this.mapSizing">
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
               style="width: 99%; height: 99%; position: absolute">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                    (dragEnd)="markerDragEnd($event)"></agm-marker>
      </agm-map>
    </div>


    <div class="text-center" [ngClass]="this.mapCss" [ngStyle]="this.mapSizing" *ngIf="locationNotIncluded">
        <BR>
        <BR>
        <BR>
        <BR>
        <BR>
        <BR>
        <span style="color: #f88d3d" (click)="selectNewLocation()">
            <i class="fas fa-map-marker-alt fa-10x"></i>
        </span>
        <BR>
        <BR>
        <BR>
        <h4>This site does not have a location.</h4>
        <BR>
        <h4>Please click the pin to add one.</h4>
    </div>


    <div class="col-lg-6 gray-bg mt-1 mat-elevation-z8" [hidden]="!showSiteTable" style="max-height: 850px !important;">
      <div style="overflow-y: scroll; max-height: 850px !important; height: 750px;">
        <table mat-table [dataSource]="dataSource" class="table site-table" matSort matSortActive="Name" matSortDirection="asc">

          <!-- Name Column -->
          <ng-container matColumnDef="Code">
            <th mat-header-cell *matHeaderCellDef style="width: 10% !important;" mat-sort-header>Code</th>
            <td mat-cell *matCellDef="let element" style="width: 10% !important;"> {{element.Code}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="City">
            <th mat-header-cell *matHeaderCellDef style="width: 18% !important;" mat-sort-header> City</th>
            <td mat-cell *matCellDef="let element" style="width: 18% !important;"> {{element.City}} </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="Address">
            <th mat-header-cell *matHeaderCellDef style="width: 30% !important;" mat-sort-header>Address</th>
            <td mat-cell *matCellDef="let element" style="width: 30% !important;"> {{element.Address}} </td>
          </ng-container>

          <!-- OMC Column -->
          <ng-container matColumnDef="OilMarketingCompany">
            <th mat-header-cell *matHeaderCellDef style="width: 25% !important;" mat-sort-header>OMC</th>
            <td mat-cell *matCellDef="let element" style="width: 25% !important;"> {{element.OilMarketingCompany}} </td>
          </ng-container>


          <!-- Edit Column -->
          <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <ul class="table-actions">
                <li class="pencil">
                    <i matTooltip="Edit Order" matTooltipPosition="above" class="fa fa-pencil" (click)="populateEditFromTable(row)"></i>
                </li>
              </ul>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"> ></tr>
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter"</td>
          </tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[15, 25, 35]" fixed showFirstLastButtons></mat-paginator>
    </div>


    <div *ngIf="showSiteInfoEditPanel" class="col-lg-6 gray-bg mt-1 mat-elevation-z8"
         style="width: 100%; max-height: 850px !important; height: 850px; ">

      <div class="gray-box-head" >
        <h2>Site Information</h2>
        <h2 *ngIf="showSelectSitePanel" style="color: red">Please select a site to edit or simply add a new one*</h2>
      </div>


      <div class="readony-row" style="max-height: 850px !important;">

        <div class="row" >
          <div class="form-group col-sm-6" *ngFor="let label of this.dataService.siteFields">
            <div class="form-field-row">
              <label class="form-label">{{label}}</label>
              &nbsp;
              &nbsp;
              <label style="color: red;" *ngIf="badCode && label == 'Code'">* ERROR THIS CODE ALREADY EXISTS *</label>

              <div class="input-field">
                <input *ngIf="label != 'Oil Marketing Company' && label != 'Code' && label != 'Suburb'"  [(ngModel)]="selectedSite[label]">
                <input *ngIf="label == 'Oil Marketing Company'"  [(ngModel)]="selectedSite.OilMarketingCompany">
                <input *ngIf="label == 'Code'" [(ngModel)]="selectedSite.Code" (ngModelChange)="checkCode($event)">
                <input *ngIf="label == 'Suburb'"  [(ngModel)]="selectedSite.Address">
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div *ngFor="let supplemental of selectedSite.SitesSupplementalInfo" class="form-group col-sm-6">
            <label>{{supplemental.Key}}</label>
            <input [(ngModel)]="supplemental.Value" class="form-control">
          </div>
        </div>
      </div>

      <div class=" gray-bg mt-1">
        <div class="gray-box-head">
          <button [disabled]="showSelectSitePanel" type="button" (click)="clearForm()" class="buttonFixCancel btn-gray ">Cancel</button>
          <button [hidden]="showSelectSitePanel" type='button' (click)="updateSite(selectedSite)" class=" btn-ornge buttonFix">Update</button>
          <button [disabled]="!allowAdd" [hidden]="!showSelectSitePanel" type='button' (click)="addSite(selectedSite)"  class=" btn-ornge buttonFix">Add New</button>
        </div>
      </div>
    </div>
  </div>
</div>
