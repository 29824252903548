import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserDataService} from '../../usersDataService/user-data.service';
import {ActivatedRoute} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-user-bulk-upload',
  templateUrl: './user-bulk-upload.component.html',
  styleUrls: ['./user-bulk-upload.component.scss']
})
export class UserBulkUploadComponent implements OnInit {

  constructor(private http: HttpClient, public dataService: UserDataService, private activatedRoute: ActivatedRoute) { }

  // Variable declarations
  files:any = [];
  arrayBuffer: any;
  file: File;
  headElements = ['First Name', 'Last Name', 'Username', 'Type'];
  usersToUpload: any = [];
  verifiedUsers: any = [];
  headers: any = [{['First Name']: null}, {['Last Name']: null}, {['Username']: null}, {['Type']: null}];
  bulkUsers: any[] = [];
  public dataSource = new MatTableDataSource<any>();
  private customer: string;
  fileName: string = "";
  public ColumnsList: string[];
  displayNoRecords: boolean = true;
  allowSubmit:  boolean = true;
  previewMessage: string =  '';
  perfectRecords: number = 0;

  // Angular Material variables
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {

    this.customer = this.activatedRoute.snapshot.paramMap.get('customer');

    if(!this.dataService.bulkUploadFields.includes('Remarks')) {
      this.dataService._bulkUploadFields.push('First Name');
      this.dataService._bulkUploadFields.push('Last Name');
      this.dataService._bulkUploadFields.push('Username');
      this.dataService._bulkUploadFields.push('Type');
      this.dataService._bulkUploadFields.push('Remarks');
    }

    this.allowSubmit = true;

  }

  generateXcelFile(){
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(this.headers);
    const wscols = [];

    for (const elem of this.headElements){
      wscols.push({ wch: elem.length + 5});
    }
    workSheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    return XLSX.writeFile(workBook, 'template.xlsx'); // initiate a file download in browser
  }

  uploadFile(event) {
    var fileExtension = event[0].name.split('.').pop();
    if (fileExtension !== "xls" && fileExtension !== "xlsx")
      console.log("Wrong File Type");

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.convertExcelToUserList(event);
      this.files.push(element.name);
      this.fileName = element.name;


    }
    this.allowSubmit = false;
  }

  convertExcelToUserList(event){
    this.file = event[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.usersToUpload = XLSX.utils.sheet_to_json(worksheet, {raw: true});
    };

  }

  validateFile(){
    this.displayNoRecords = false;

    for (let index = 0; index < this.usersToUpload.length; index++) {

      if(this.usersToUpload[index]['First Name'] === undefined){
        this.usersToUpload[index].message = 'Missing First Name';
      }

      if(this.usersToUpload[index]['Last Name'] === undefined){
        this.usersToUpload[index].message = 'Missing Last Name';
      }

      if(this.usersToUpload[index].Type === undefined){
        this.usersToUpload[index].message = 'Missing User Type';
      }

      if(this.usersToUpload[index].Username === undefined){
        this.usersToUpload[index].message = 'Missing Username';
      }

      let validType = false;
      let userTypes = '';

      for (let x = 0; x < this.dataService.userTypes.length; x++)
      {
        if(x !== this.dataService.userTypes.length - 1) {
          userTypes = userTypes.concat(this.dataService.userTypes[x].Name, ', ');
        }
        else{
          userTypes = userTypes.concat(' and ' + this.dataService.userTypes[x].Name, ' ');
        }

        if(this.dataService.userTypes[x].Name === this.usersToUpload[index].Type) {
          validType = true;
        }
      }

      if(validType === false){
        this.usersToUpload[index].message = 'Only ' + userTypes + ', are valid user types.';
      }

      for (let x = 0; x < this.dataService.userList.length; x++){
        if(this.usersToUpload[index].Username === this.dataService.userList[x].Username){
          this.usersToUpload[index].message = 'Username ' + this.usersToUpload[index].Username + ' already exists.';
        }
      }

      if(this.usersToUpload[index].message === undefined) {
        this.usersToUpload[index].message = '';
        this.perfectRecords++;
        this.verifiedUsers.push(this.usersToUpload[index]);
      }
    }

    this.dataSource = this.usersToUpload;

    if(this.perfectRecords !== 0)
      this.previewMessage = this.perfectRecords + " Record(s) can be successfully uploaded out of " + this.usersToUpload.length + ". Errors can be viewed by hovering over Failed in the \"Remarks\" section";
    else
      this.previewMessage = this.perfectRecords + " Record(s) can be successfully uploaded out of " + this.usersToUpload.length + ".";


  }

  clearData(){
    this.usersToUpload = [];
    this.dataSource = null;
    this.fileName = null;
    this.previewMessage = '';
  }

  uploadUsers(){

    for (let x = 0; x < this.usersToUpload.length; x++){

      this.usersToUpload[x].FirstName = this.usersToUpload[x]['First Name'];
      this.usersToUpload[x].LastName = this.usersToUpload[x]['Last Name'];

      for (let y = 0; y < this.dataService.userTypes.length; y++) {
        if(this.dataService.userTypes[y].Name === this.usersToUpload[x].Type)
        {
          this.usersToUpload[x].UserTypeId = this.dataService.userTypes[y].Id;
        }
      }
    }

    this.dataService.bulkAddUsers(this.usersToUpload, this.dataService.customer);

  }

}
