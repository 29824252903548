import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {ListObjects} from '../interfaceModels/listObjects';
import {ListInterface} from '../interfaceModels/listInterface';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  _dropDowns: any;
  set dropDowns(value: any){
    this._dropDowns = value;
  }
  get dropDowns(): any {
    return this._dropDowns;
  }

  _sample: ListObjects;
  set sample(value: ListObjects){
    this._sample = value;
  }
  get sample(): ListObjects {
    return this._sample;
  }

  _sampleNameFilter: string = '';
  set sampleNameFilter(value: string){
    this._sampleNameFilter = value;
  }
  get sampleNameFilter(): string {
    return this._sampleNameFilter
  }

  _dateFilter: Date = null;
  set dateFilter(value: Date){
    this._dateFilter = value;
  }
  get dateFilter(): Date {
    return this._dateFilter
  }

  apiUrl = 'https://fqa-astm-ksa-app.azurewebsites.net/api/';

  constructor(private http: HttpClient, private router: Router) { }

  // HTTP Calls

  // Get the results

  public getResultsList(page: number, pageSize: number, sampleId: string, date: string): Observable<ListInterface>{

    // This if statment is used to determine if the user left the date blank. For some reason if its blank it defaults to the date in question and it messes up the api call.
    if(date === '12/31/1969')
    {
      date = '';
    }

    const fullUrl = `${this.apiUrl}GetFormList?code=WgH60238nxGWA5C9T48LQXfZ0kkmDfORVL0emcqanaU6eOzjtsY0Jw==&orderDirection=desc&page=${page + 1}&pageSize=${pageSize}&sampleId=${sampleId}&startDate=${date}&endDate=${date}`;
    return this.http.get<ListInterface>(fullUrl);
  }

  // Get Properties

  public getProperties(id: number): Observable<ListObjects>{

     const fullUrl = `${this.apiUrl}GetForm?code=WgH60238nxGWA5C9T48LQXfZ0kkmDfORVL0emcqanaU6eOzjtsY0Jw==&formID=${id}`;
     return this.http.get<ListObjects>(fullUrl);
  }

  // Get Configs

  public getConfigs(){

    const fullUrl = `${this.apiUrl}GetConfigList?code=WgH60238nxGWA5C9T48LQXfZ0kkmDfORVL0emcqanaU6eOzjtsY0Jw==`;
    return this.http.get(fullUrl);
  }


  // Update Sample

  public updateSample(sample: any) {

    const fullUrl = `${this.apiUrl}UpdateForm?code=WgH60238nxGWA5C9T48LQXfZ0kkmDfORVL0emcqanaU6eOzjtsY0Jw==`;

    return this.http.post(fullUrl, sample)
      .subscribe(
        res => {
          console.log(res);
          this.router.navigate(['/ASTM/KSA']);
        },
        err => {
          console.log('error');
          this.router.navigate(['/ASTM/KSA']);
        }
      );
  }

  public addSample(sample: any){

    const fullUrl = `${this.apiUrl}AddForm?code=WgH60238nxGWA5C9T48LQXfZ0kkmDfORVL0emcqanaU6eOzjtsY0Jw==`;

    return this.http.post(fullUrl, sample)
      .subscribe(
        res => {
          console.log(res);
          this.router.navigate(['/ASTM/KSA']);
        },
        err => {
          console.log('error');
          this.router.navigate(['/ASTM/KSA']);
        }
      );
  }


}
