import { Component, OnInit, AfterViewInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {GhanaFmmService} from '../../dataServiceGhanaFMM/ghana.fmm.service';
import {EditResourcesApi} from '../../interfaceModels/editResourcesApi';
import {FullOrderInterface} from '../../interfaceModels/fullOrderInterface';
import {Compartments} from '../../interfaceModels/compartments';



@Component({
  selector: 'app-edit-order',
  templateUrl: './ghana-fmm-edit-order.component.html',
  styleUrls: ['./ghana-fmm-edit-order.component.css']
})
export class GhanaFmmEditOrderComponent implements OnInit{

  // Get order id from header to get resources.
  orderId = this.actRoute.snapshot.paramMap.get('id');

  // Dropdown list vars
  statuses: any [] = [{id: 1, name: 'Completed'}, {id: 2 , name: 'Cancelled'}, {id: 3, name: 'Deleted'}];
  editResources: EditResourcesApi;
  fullOrder: FullOrderInterface;
  displayedColumns: string[] = ['number', 'productName', 'productType', 'productQuantity',  'markerName', 'markerQuantity', 'editCompartment'];
  isLoadingResults = true;


  constructor(private actRoute: ActivatedRoute, public dataService: GhanaFmmService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void{
    this.isLoadingResults = true;
    // grab resources for the order
    this.dataService.getEditResources(this.orderId).subscribe((data: EditResourcesApi) => {
      this.editResources = data;
      this.dataService._products = data.Products;
    });

    // grab the entire order with all of its info
    this.dataService.getSingleOrder(this.orderId).subscribe((data: FullOrderInterface) => {
      this.fullOrder = data;
    });
  }

  updateOrder(){
    // Trailer Number is assigned at the beginning because Ghana uses the same numbers for Trailer and Header for Vehicle Id
    this.fullOrder.TrailerNumber = this.fullOrder.TankerHeadNumber;

    console.log(this.fullOrder);

    this.dataService.updateOrder(this.fullOrder);
  }

  passCompartment(compartmentEdit: Compartments){
    this.dataService._compartment = compartmentEdit;
  }


}
