
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<div class="authentixBackgroundColor filter-role top-title-bar">
  <a class="adjustments">Reports</a>
  <div>
    <button class="btn site-btn -pull-left" routerLink="AddSample">Add New</button>

    <mat-form-field floatLabel="never" class="menu-date-picker">
      <input [(ngModel)]="dataService.dateFilter" [matDatepicker]="startDate"  matInput>
      <mat-placeholder class="placeholder">Report Import Date</mat-placeholder>
      <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>

    <input [(ngModel)]="dataService.sampleNameFilter"  type="text" placeholder="Sample Name" autocomplete="off" name="depot" class="text-box">|
    <button (click)="updateTable()" class="btn btn-defult site-btn" >Update Search</button>
  </div>
</div>


<div class="example-container mat-elevation-z8">


  <ngx-spinner [fullScreen]="false" bdColor="rgba(51,51,51,0.8)" size="medium"  color="#dd7322" type="ball-spin-clockwise-fade">
  </ngx-spinner>

  <div class="example-table-container">

    <table mat-table [dataSource]="data.response" class="example-table site-table"
    >

      <!-- Sample Date Column -->
      <ng-container matColumnDef="Sample">
        <th mat-header-cell *matHeaderCellDef>Sample</th>
        <td mat-cell *matCellDef="let row">{{row.atxSampleId}} &nbsp;
          <span [ngClass]="{
            'notSus' : row.suspect === false || row.confidenceLevel === 100,
            'aLittleSus' : row.confidenceLevel < 100,
            'verySus' : row.confidenceLevel < 90

         }">
            <i class="fas fa-flag"></i>
          </span></td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> Report Type</th>
        <td mat-cell *matCellDef="let row">{{row.astmFormId}}</td>
      </ng-container>

      <!-- Data Entry Date -->
      <ng-container matColumnDef="DateEntryDate">
        <th mat-header-cell *matHeaderCellDef>Report Import Date</th>
        <td mat-cell *matCellDef="let row">
          {{row.createdOn | date}}
        </td>
      </ng-container>

      <!-- Report Date Column -->
      <ng-container matColumnDef="ReportDate">
        <th mat-header-cell *matHeaderCellDef>Certification Number</th>
        <td mat-cell *matCellDef="let row">
          {{row.certificationNumber}}
        </td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="Location">
        <th mat-header-cell *matHeaderCellDef>Report Date</th>
        <td mat-cell *matCellDef="let row">{{row.reportDate | date}}</td>
      </ng-container>

      <ng-container matColumnDef="AnalyzedDate">
        <th mat-header-cell *matHeaderCellDef>Date Sampled</th>
        <td  mat-cell *matCellDef="let row">
          {{row.dateSampled | date}}
          </td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let row">
          <ul class="table-actions">
            <li class="pencil" (click)="passSample(row)">
              <i matTooltip="Edit Order" matTooltipPosition="above" class="fa fa-pencil"></i>
            </li>
          </ul>
        </td>
      </ng-container>

      <!-- Row Styling -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>


</div>

<!-- Paging, if you noticed it is fixed at the bottom  of the page. This is really important for the axis portal-->
<mat-paginator fixed [length]="resultsLength"  [pageSize]="15" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons></mat-paginator>
