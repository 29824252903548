import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {FullOrderInterface} from "../../FMM/Ghana/interfaceModels/fullOrderInterface";

@Injectable({
  providedIn: 'root'
})
export class DuplicateSiteHttpCallsService {

  constructor(private http: HttpClient, private router: Router) { }

  public getDuplicateSiteList(customer: string): Observable<any> {
    const fullUrl = `https://fsm-admin-portal-api.azurewebsites.net/api/GetDuplicateSites?code=OIRAaX64qX5L5y2EFaapjUZJKftl9UqKZ00S/uykSn6RmaAhYBNjdQ==&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }

  public getRelatedDuplicateSites(customer: string, siteCode: string): Observable<any> {
    const fullUrl = `https://fsm-admin-portal-api.azurewebsites.net/api/GetSitesFromCode?code=WmayYUkcid8waYeSFckiI0mcd6i88IToG/gvcbMQqoEInlOu2f3ITA==&siteCode=${siteCode}&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }

  public removeDuplicates(customer: string, siteId: string, siteCode: string){
    const fullUrl = `https://fsm-admin-portal-api.azurewebsites.net/api/ConsolidateDuplicateSites?code=FCcVAzbYNmzuQ/HrbmHhHLTwU0tZEUNddVOFrswODB6YyGPKf0AVcw==&siteId=${siteId}&siteCode=${siteCode}&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }

}
