
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<div class="authentixBackgroundColor filter-role top-title-bar">
  <a class="adjustments">Samples</a>
  <div>

    <mat-form-field floatLabel="never" class="menu-date-picker">
      <input [(ngModel)]="dataService.dateFilter" [matDatepicker]="startDate"  matInput>
      <mat-placeholder class="placeholder">Report Import Date</mat-placeholder>
      <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>


    <input [(ngModel)]="dataService.sampleNameFilter"  type="text" placeholder="Sample Name" autocomplete="off" name="depot" class="text-box">|
    <button style="text-transform: none !important; height: 36px;" (click)="updateTable()" class="btn btn-defult site-btn" >Update Search</button>
    <button style="text-transform: none !important; height: 36px;" routerLink="AddSample" class="btn btn-defult site-btn" >Add New</button>
  </div>
</div>


<div class="example-container mat-elevation-z8">

  <div class="example-table-container">

    <table mat-table [dataSource]="data.listResult" class="example-table site-table"
    >

      <!-- Sample Date Column -->
      <ng-container matColumnDef="Sample">
        <th mat-header-cell *matHeaderCellDef>Sample</th>
        <td mat-cell *matCellDef="let row">{{row.serial}}</td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row">{{row.sampleType}}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let row">{{row.sampleDescription}}</td>
      </ng-container>

      <!-- Report Date Column -->
      <ng-container matColumnDef="ReportDate">
        <th mat-header-cell *matHeaderCellDef>Report Date</th>
        <td mat-cell *matCellDef="let row">
        {{row.reportDate | date}}
        </td>
      </ng-container>

      <!-- Sample Date Column -->
      <ng-container matColumnDef="SampleDate">
        <th mat-header-cell *matHeaderCellDef>Sample Date</th>
        <td mat-cell *matCellDef="let row">{{row.sampleDate | date}}</td>
      </ng-container>

      <!-- Analyzed Date Column -->
      <ng-container matColumnDef="AnalyzedDate">
        <th mat-header-cell *matHeaderCellDef>Analyzed Date</th>
        <td mat-cell *matCellDef="let row">{{row.sampleAnalyzedDate | date}}</td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="Location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let row">{{row.sampleLocation}}</td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let row">
          <ul class="table-actions">
            <li class="pencil" routerLink="UpdateSample" (click)="passSample(row)">
              <i matTooltip="Edit Order" matTooltipPosition="above" class="fa fa-pencil"></i>
            </li>
          </ul>
        </td>
      </ng-container>

      <!-- Row Styling -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>


</div>

<!-- Paging, if you noticed it is fixed at the bottom  of the page. This is really important for the axis portal-->
<mat-paginator fixed [length]="resultsLength"  [pageSize]="15" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons></mat-paginator>
