<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<div class="top-title-bar" style="position: sticky !important; top: 0px; z-index: 3">
  <h2 class="page-title">Order List</h2>
  <div class="filter-role col-sm text-right p-none">


    <mat-form-field floatLabel="never" class="menu-date-picker">
      <input [(ngModel)]="dataService.dateFilter" [matDatepicker]="startDate"  matInput>
      <mat-placeholder class="placeholder">Date</mat-placeholder>
      <mat-datepicker-toggle [for]="startDate" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>

    <input [(ngModel)]="dataService.depotFilter"  type="text" placeholder="Depot" autocomplete="off" name="depot" class="text-box">
    <input [(ngModel)]="dataService.orderNumberFilter" type="text" placeholder="Order Number" autocomplete="off" name="orderName" class="text-box">
    <input [(ngModel)]="dataService.orderNameFilter" type="text" placeholder="Order ID" autocomplete="off" name="orderNumber"
           class="text-box">
    |
    <button class="btn btn-defult site-btn" (click)="updateTable()">Update Search</button>
  </div>
</div>

<div class="example-container mat-elevation-z8">

  <ngx-spinner *ngIf="isLoadingResults" [fullScreen]="false" bdColor="rgba(51,51,51,0.8)" size="medium"  color="#dd7322" type="ball-spin-clockwise-fade">
  </ngx-spinner>

  <div class="example-table-container">

    <table mat-table [dataSource]="data" class="example-table site-table"
           >

      <!-- Number Column -->
      <ng-container matColumnDef="OrderDate">
        <th mat-header-cell *matHeaderCellDef>Order Date</th>
        <td mat-cell *matCellDef="let row">{{row.OrderDate | date}}</td>
      </ng-container>

      <!-- Title Column -->
      <ng-container matColumnDef="OrderNumber">
        <th mat-header-cell *matHeaderCellDef>Order Number</th>
        <td mat-cell *matCellDef="let row">{{row.OrderNumber}}</td>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="OrderID">
        <th mat-header-cell *matHeaderCellDef>Order ID</th>
        <td mat-cell *matCellDef="let row">{{row.OrderUniqueId}}</td>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">
          <button
            [ngClass]="{'Completed': row.OrderStatus === 'Completed', 'Deleted': row.OrderStatus === 'Deleted', 'Cancelled': row.OrderStatus === 'Cancelled'}">
            {{row.OrderStatus}}
          </button>
        </td>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="Depot">
        <th mat-header-cell *matHeaderCellDef>Depot</th>
        <td mat-cell *matCellDef="let row">{{row.DepotName}}</td>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let row">
          <ul class="table-actions">
            <li class="pencil" [routerLink]="['editOrder/', row.OrderId]"(click)="passFKEYS(row)">
              <i matTooltip="Edit Order" matTooltipPosition="above" class="fa fa-pencil"></i>
            </li>
          </ul>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      There are no orders for the date selected. <BR> Please choose a different date.
    </div>

  </div>


</div>
<mat-paginator fixed [length]="resultsLength"  [pageSize]="15" [pageSizeOptions]="[15, 30, 50]" showFirstLastButtons></mat-paginator>
