import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {formatDate} from '@angular/common';
import {Order} from '../../interfaceModels/order.model';
import {MatPaginator} from '@angular/material/paginator';
import {HttpClient} from '@angular/common/http';
import {GhanaFmmService} from '../../dataServiceGhanaFMM/ghana.fmm.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DropDownApi} from '../../interfaceModels/dropDownApi';


@Component({
  selector: 'app-get-orders',
  templateUrl: './ghana-fmm-get-orders.component.html',
  styleUrls: ['./ghana-fmm-get-orders.component.css']
})
export class GhanaFmmGetOrdersComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['OrderDate', 'OrderNumber', 'OrderID', 'Status', 'Depot', 'Edit'];
  data: Order[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private http: HttpClient, public dataService: GhanaFmmService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    if(this.dataService._dropDowns == null) {
      this.dataService.getDropDowns().subscribe((data: DropDownApi) => {
        this.dataService._dropDowns = data;
        this.isLoadingResults = false;
        this.spinner.hide();
      });
    }
  }

  ngAfterViewInit(){


    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.spinner.show();
          return this.dataService.getOrders(formatDate(this.dataService.dateFilter,'MM/dd/yyyy', 'en'), this.dataService.depotFilter, this.dataService.orderNumberFilter, this.dataService.orderNameFilter,
            this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          if(this.dataService.dropDowns != null) {
            this.isLoadingResults = false;
            this.spinner.hide();
          }
          this.isRateLimitReached = false;
          this.resultsLength = data.count;

         if(this.resultsLength === 0)
         {
           this.isRateLimitReached = true;
         }
          return data.orders;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }


  updateTable(){
    this.paginator.pageIndex =  0;
    this.isLoadingResults = true;
    this.dataService.getOrders(formatDate(this.dataService.dateFilter,'MM/dd/yyyy', 'en'), this.dataService.depotFilter, this.dataService.orderNumberFilter, this.dataService.orderNameFilter, this.paginator.pageIndex, this.paginator.pageIndex)
      .pipe(
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.count;
          this.isRateLimitReached = false;
          if(this.resultsLength == 0)
          {
           this.isRateLimitReached = true;
          }

          // if(this.dataService.dateFilter == null)
          // {
          //   this.dataService._dateFilter = new Date(data.orders[0].OrderDate);
          // }

          return data.orders;
                  }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  passFKEYS(order: Order){
this.dataService._foreignKeys = order;
  }

}


