import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DatePipe} from '@angular/common';
import {formatDate} from '@angular/common';
import {Order} from '../../interfaceModels/order.model';
import {DataService} from '../../dataServiceKenyaFMM/data.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DropDownApi} from '../../interfaceModels/dropDownApi';



@Component({
  selector: 'app-get-orders',
  templateUrl: './kenya-fmm-get-orders.component.html',
  styleUrls: ['./kenya-fmm-get-orders.component.css']
})
export class KenyaFmmGetOrdersComponent implements OnInit, AfterViewInit {

// Set variables on initialization

  displayedColumns: string[] = ['OrderDate', 'OrderNumber', 'OrderID', 'Status', 'Terminal', 'Edit'];
  data: Order[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  // Load view children for special components
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private http: HttpClient, public dataService: DataService, private spinner: NgxSpinnerService) { }

  // Loading our large lists on Init and stores them in the session memory so it will only have to load once to save time and data usage.
  ngOnInit() {

    if (this.dataService._dropDowns == null) {
      this.spinner.show();

      this.dataService.getDropDowns().subscribe((data: DropDownApi) => {
        this.dataService._dropDowns = data;
        if (this.resultsLength === 0)
        {
          this.isRateLimitReached = true;
        }
        this.spinner.hide();
        this.isLoadingResults = false;
      });
    }
  }

  // Since the page features a loading wheel the table contents need to be loaded after init for the wheel to show properly.
  ngAfterViewInit(){
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.spinner.show();

          return this.dataService.getOrders(formatDate(this.dataService.dateFilter, 'MM/dd/yyyy', 'en'), this.dataService.depotFilter, this.dataService.orderNumberFilter, this.dataService.orderNameFilter,
            this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          if (this.dataService.dropDowns != null) {
            this.isLoadingResults = false;
            this.spinner.hide();
          }
          this.isRateLimitReached = false;
          this.resultsLength = data.count;



          return data.orders;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  // Applies new selected filters to query and fetches updated results.
  updateTable(){
    this.paginator.pageIndex =  0;
    this.isLoadingResults = true;
    this.spinner.show();
    this.dataService.getOrders(formatDate(this.dataService.dateFilter, 'MM/dd/yyyy', 'en'), this.dataService.depotFilter, this.dataService.orderNumberFilter, this.dataService.orderNameFilter, this.paginator.pageIndex, this.paginator.pageIndex)
      .pipe(
        map(data => {

          this.resultsLength = data.count;
          this.isRateLimitReached = false;
          if (this.resultsLength === 0)
          {
           this.isRateLimitReached = true;
          }

          this.spinner.hide();
          this.isLoadingResults = false;
          return data.orders;
                  }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the API has reached its rate limit or returns empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }


  // This is a little method I use to store the selected order to edit in memory so that the edit order page loads a bit faster.
  passFKEYS(order: Order){
this.dataService._foreignKeys = order;
  }

}


