import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {Site} from '../../SiteManagment/interfaceModels/siteInterface';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  _customer: string;
  set customer(value: string) {
    this._customer = value;
  }
  get customer(): string {
    return this._customer;
  }

  _bulkUploadFields: Array<string> = [];
  set bulkUploadFields(value: Array<string>){
    this._bulkUploadFields = value;
  }
  get bulkUploadFields(): Array<string> {
    return this._bulkUploadFields;
  }

  _userTypes: any;
  set userTypes(value: any){
    this._userTypes = value;
  }
  get userTypes(): any {
    return this._userTypes;
  }

  _userList: any;
  set userList(value: any){
    this._userList = value;
  }
  get userList(): any {
    return this._userList;
  }

  apiUrl = 'https://fsm-admin-portal-api.azurewebsites.net/api/';

  constructor(private http: HttpClient, private router: Router, private spinner: NgxSpinnerService) {
  }

  public getUsers(customer: string): Observable<any> {
    const fullUrl = `${this.apiUrl}GetUsers?code=J40YmQdRKkOo7rWiu9d34VBaLxYlIvdntww3gVs1Tt-MAzFuIWpcgA==&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }

  public getUserTypes(customer: string): Observable<any> {
    const fullUrl = `${this.apiUrl}GetUserTypes?code=FV33mblNnxuuyB26DodRF-hx0t6eQz1i6cNCAeYOZdHlAzFuxvm-IA==&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }


  public addNewUser(user: any, customer: string) {
    return new Promise((resolve, reject) => {
      const fullUrl = `${this.apiUrl}AddNewUser?code=XkKR8WGhtHPwwSxE0RJATFgAKxfoICWHf0t0TOewDYu6AzFuZNt9pg==&customer=${customer}`;

      console.log(user);

      return this.http.post<any>(fullUrl, user)
        .subscribe(
          res => {
            console.log(res);
            resolve();
          },
          err => {
            console.log(err);
            resolve();
          }
        );
    });
  }

  public updateUser(user: any, customer: string) {
    return new Promise((resolve, reject) => {
      const fullUrl = `${this.apiUrl}UpdateUser?code=Pp4d1BptJ7quaqCJ8SvF68WWNe1qdio-FHmBCchUBOXIAzFuuuOTeg==&customer=${customer}`;

      console.log(user);

      return this.http.post<any>(fullUrl, user)
        .subscribe(
          res => {
            console.log(res);
            resolve();
          },
          err => {
            console.log(err);
            resolve();
          }
        );
    });
  }

  public bulkAddUsers(userList: any, customer: string){
    const fullUrl = `${this.apiUrl}AddUsers?code=zqOrR4LXFmXFOctlE8lz08oFxQYZYxc5ieNYj7qzQscRAzFu3K8o_Q==&customer=${customer}`;
    return this.http.post<any>(fullUrl, userList)
      .subscribe(
        res => {
          console.log(res);
          this.router.navigate(['/FSM/' + customer + '/Users']);
        },
        err => {
          console.log(err);
        }
      );
  }

}
