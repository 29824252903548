import {Component, OnInit, ViewChild} from '@angular/core';
import {DuplicateSiteHttpCallsService} from '../duplicate-site-http-calls.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-duplicate-site-wizard',
  templateUrl: './duplicate-site-wizard.component.html',
  styleUrls: ['./duplicate-site-wizard.component.scss']
})
export class DuplicateSiteWizardComponent implements OnInit {

  constructor(public service: DuplicateSiteHttpCallsService, private activatedRoute: ActivatedRoute) { }

  displayedColumns: string[] = ['Code', 'Count', 'Edit'];
  dataSource: MatTableDataSource<any>;
  duplicateSiteGroup: any;
  siteSelected : boolean;
  siteCode: string;
  siteId : string;
  showGreeting: boolean;
  showConfirmation: boolean;
  customer: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {

    this.customer = this.activatedRoute.snapshot.paramMap.get('customer');

    this.showConfirmation = false;
    this.showGreeting = true;
    this.siteSelected = false;

    this.service.getDuplicateSiteList(this.customer).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    });

  }

  getRelatedDuplicates(code: string) {
    this.showGreeting = false;
    this.showConfirmation = false;
    this.service.getRelatedDuplicateSites(this.customer, code).subscribe((data: any) => {
      this.duplicateSiteGroup = data.reverse();
      this.siteSelected = true;
      this.siteCode = code;
      console.log(this.duplicateSiteGroup);
    });
  }

  validateSite(){
    this.service.removeDuplicates(this.customer, this.siteId, this.siteCode).subscribe((data: any) => {
      console.log(data, 'this logged');
      this.siteSelected = false;
      this.showGreeting = false;
      this.showConfirmation = true;

      this.service.getDuplicateSiteList(this.customer).subscribe((newData: any) => {
        this.dataSource = new MatTableDataSource(newData);
        this.dataSource.paginator = this.paginator;
      });

    });


  }

  setSite(id: string){
    console.log(id);
    this.siteId = id;
  }

  cancelProcess(){
    this.siteSelected = false;
    this.showGreeting = true;
  }
}


