import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import { environment } from '../environments/environment';



@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _router: Router) { }

  canActivate() {

    const production = true;

    // @ts-ignore
    if (production === false) {
      return true;
    }

    if (window.self !== window.top && localStorage.getItem('AuthToken')!=null) {
      var tdate=AES.decrypt(localStorage.getItem('AuthToken'), "SecretKey").toString(enc.Utf8);
      if(!Date.parse(tdate) || this.dateDiff(tdate)>environment.authentixTokenExpDuration)
      {
        this._router.navigate(['/unauthorized']);
      }
      else{
        return true;
      }

    } else {
      this._router.navigate(['/unauthorized']);
    }
  }

  dateDiff(date)
  {
    var diffMs = (new Date().getTime()-new Date(date).getTime());
    return Math.round(((diffMs % 86400000) % 3600000) / 60000);
  }
}
