import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {GhanaFmmGetOrdersComponent} from '../programs/FMM/Ghana/components/get-orders/ghana-fmm-get-orders.component';
import {GhanaFmmEditOrderComponent} from '../programs/FMM/Ghana/components/edit-order/ghana-fmm-edit-order.component';
import {GhanaFmmEditCompartmentComponent} from '../programs/FMM/Ghana/components/edit-compartment/ghana-fmm-edit-compartment.component';
import {KenyaFmmGetOrdersComponent} from '../programs/FMM/Kenya/components/get-orders/kenya-fmm-get-orders.component';
import {KenyaFmmEditOrderComponent} from '../programs/FMM/Kenya/components/edit-order/kenya-fmm-edit-order.component';
import {KenyaFmmEditCompartmentComponent} from '../programs/FMM/Kenya/components/edit-compartment/kenya-fmm-edit-compartment.component';
import {ZambiaAstmViewSamplesComponent} from '../programs/ASTM/Zambia/components/view-samples/zambia-astm-view-samples.component';
import {ZambiaAstmEditSampleComponent} from '../programs/ASTM/Zambia/components/edit-sample/zambia-astm-edit-sample.component';
import {ZambiaAstmAddSampleComponent} from '../programs/ASTM/Zambia/components/add-sample/zambia-astm-add-sample.component';
import {KsaAstmViewSamplesComponent} from '../programs/ASTM/KingdomSaudiArabia/components/view-samples/ksa-astm-view-samples.component';
import {KsaAstmEditSampleComponent} from '../programs/ASTM/KingdomSaudiArabia/components/edit-sample/ksa-astm-edit-sample.component';
import {KsaAstmAddSampleComponent} from '../programs/ASTM/KingdomSaudiArabia/components/add-sample/ksa-astm-add-sample.component';
import {MoeKsaAstmViewSamplesComponent} from '../programs/ASTM/MinistryOfEnergyKSA/components/view-samples/moe-ksa-astm-view-samples.component';
import {MoeKsaAstmUpdateSampleComponent} from '../programs/ASTM/MinistryOfEnergyKSA/components/update-sample/moe-ksa-astm-update-sample.component';
import {MoeKsaAstmAddSampleComponent} from '../programs/ASTM/MinistryOfEnergyKSA/components/add-sample/moe-ksa-astm-add-sample.component';
import {DuplicateSiteWizardComponent} from '../programs/FSM/DuplicateSiteConsolidation/duplicate-site-wizard/duplicate-site-wizard.component';
import {AuthGuard} from './auth.guard';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {ViewSitesComponent} from "../programs/FSM/SiteManagment/components/view-sites/view-sites.component";
import {BulkSiteAddComponent} from "../programs/FSM/SiteManagment/components/bulk-site-add/bulk-site-add.component";
import {ViewUsersComponent} from '../programs/FSM/UserManagment/components/view-users/view-users.component';
import {UserBulkUploadComponent} from '../programs/FSM/UserManagment/components/user-bulk-upload/user-bulk-upload.component';




const routes: Routes = [

  // Unauthorized
  { path: 'unauthorized' , component: UnauthorizedComponent},

  // Ghana FMM
  {path: 'FMM/Ghana', component: GhanaFmmGetOrdersComponent, canActivate: [AuthGuard]},
  { path: 'FMM/Ghana/editOrder/:id', component: GhanaFmmEditOrderComponent, canActivate: [AuthGuard]},
  { path: 'FMM/Ghana/editOrder/:id/editCompartment', component: GhanaFmmEditCompartmentComponent, canActivate: [AuthGuard]},

  // Kenya FMM
  {path: 'FMM/Kenya', component: KenyaFmmGetOrdersComponent, canActivate: [AuthGuard]},
  { path: 'FMM/Kenya/editOrder/:id', component: KenyaFmmEditOrderComponent, canActivate: [AuthGuard]},
  { path: 'FMM/Kenya/editOrder/:id/editCompartment', component: KenyaFmmEditCompartmentComponent, canActivate: [AuthGuard]},

  // Zambia ASTM
  {path: 'ASTM/Zambia', component: ZambiaAstmViewSamplesComponent, canActivate: [AuthGuard]},
  {path: 'ASTM/Zambia/EditSample', component: ZambiaAstmEditSampleComponent, canActivate: [AuthGuard]},
  {path: 'ASTM/Zambia/AddSample', component: ZambiaAstmAddSampleComponent, canActivate: [AuthGuard]},

  // Kingdom of Saudi Arabia ASTM
  {path: 'ASTM/KSA', component: KsaAstmViewSamplesComponent, canActivate: [AuthGuard]},
  {path: 'ASTM/KSA/EditSample', component: KsaAstmEditSampleComponent, canActivate: [AuthGuard]},
  {path: 'ASTM/KSA/AddSample', component: KsaAstmAddSampleComponent, canActivate: [AuthGuard]},

  // Ministry of Energy KSA ASTM
  {path: 'ASTM/MOE', component: MoeKsaAstmViewSamplesComponent, canActivate: [AuthGuard]},
  {path: 'ASTM/MOE/UpdateSample', component: MoeKsaAstmUpdateSampleComponent, canActivate: [AuthGuard]},
  {path: 'ASTM/MOE/AddSample', component: MoeKsaAstmAddSampleComponent, canActivate: [AuthGuard]},

  // FSM Site Consolidation
  {path: 'FSM/:customer/DuplicateSiteWizard', component: DuplicateSiteWizardComponent, canActivate: [AuthGuard]},
  {path: 'FSM/:customer', component: ViewSitesComponent, canActivate: [AuthGuard]},
  {path: 'FSM/:customer/bulkUploadSites', component: BulkSiteAddComponent, canActivate: [AuthGuard]},
  {path: 'FSM/:customer/Users', component: ViewUsersComponent, canActivate: [AuthGuard]},
  {path: 'FSM/:customer?/Users/bulkUploadUsers', component: UserBulkUploadComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
