import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe, formatDate} from '@angular/common';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {DataService} from '../../dataServiceZambiASTM/data.service';
import {ListObjects} from '../../interfaceModels/listObjects';

@Component({
  selector: 'app-view-samples',
  templateUrl: './zambia-astm-view-samples.component.html',
  styleUrls: ['./zambia-astm-view-samples.component.scss']
})
export class ZambiaAstmViewSamplesComponent implements AfterViewInit, OnInit {

  data: any = [];
  displayedColumns: string[] = ['Sample', 'Type', 'DateEntryDate', 'ReportDate',  'Location', 'AnalyzedDate', 'Edit'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dataService: DataService, public spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit() {
    if(this.dataService._dropDowns == null) {
      this.dataService.getConfigs().subscribe((data: any) => {
        this.dataService._dropDowns = data;
        this.isLoadingResults = false;
        this.spinner.hide();
      });
    }
    }

  ngAfterViewInit() {

    this.spinner.show();

    merge(this.paginator.page)

      .pipe(
        startWith({}),
        switchMap(() => {
          this.spinner.show();
          this.isLoadingResults = true;
          return this.dataService.getResultsList(this.paginator.pageIndex, this.paginator.pageSize, this.dataService.sampleNameFilter, formatDate(this.dataService.dateFilter,'MM/dd/yyyy', 'en'));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.totalCount;
          if (this.dataService._dropDowns != null) {
            this.spinner.hide();
          }
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  // Applies new selected filters to query and fetches updated results.
  updateTable(){
    this.spinner.show();
    this.paginator.pageIndex =  0;
    this.isLoadingResults = true;
    this.dataService.getResultsList(this.paginator.pageIndex, this.paginator.pageSize, this.dataService.sampleNameFilter, formatDate(this.dataService.dateFilter, 'MM/dd/yyyy', 'en'))
      .pipe(
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.spinner.hide();
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the API has reached its rate limit or returns empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  passSample(sample: ListObjects){
    this.dataService._sample = sample;
    this.router.navigate(['ASTM/Zambia/EditSample']);
  }

}



