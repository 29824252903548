import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {GhanaFmmService} from '../../dataServiceGhanaFMM/ghana.fmm.service';

@Component({
  selector: 'app-edit-compartment',
  templateUrl: './ghana-fmm-edit-compartment.component.html',
  styleUrls: ['./ghana-fmm-edit-compartment.component.css']
})
export class GhanaFmmEditCompartmentComponent implements OnInit {

  compartmentEdit = this.dataService._compartment;

  constructor(private actRoute: ActivatedRoute, public dataService: GhanaFmmService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  updateCompartment(){
    this.dataService.updateCompartment(this.compartmentEdit);
  }

  productChange(){
    for (let i = 0; i < this.dataService.products.length; i++) {
      if (this.compartmentEdit.ProductId == this.dataService.products[i].Id) {
        this.compartmentEdit.ProductType = this.dataService.products[i].ProductTypeName;
        this.compartmentEdit.MarkerName = this.dataService.products[i]. MarkerName;
        this.compartmentEdit.MarkerId = this.dataService.products[i].MarkerId;
        this.compartmentEdit.MarkerQuantity = this.compartmentEdit.ProductQuantity * this.dataService.products[i].MarkingRatio;
      }
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log('Waiting for save to process'));
  }

}
