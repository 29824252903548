<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
<div class="top-title-bar">
  <h2 class="page-title">Bulk Upload</h2>
</div>
<div class="white-box">
  <div class="bulk-location-dv">
    <div class="upload-main-dv">
      <div class="row m-none">
        <div class="col-lg-7 col-md-5 col-sm-6 plr-5">
          <div class="center p-10">
            <div class="mydiv" style="background-color: #eaeaea !important; border:2px dashed lightgrey; border-radius: 25px; " (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
              <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
              <div class="row align-items-center" style="padding-top: 2%; padding-bottom: 2%">
                <div class="col-md-5" style="padding: 0px;">
                  <i class="fa fa-cloud-upload " style="font-size: 80px; color: #337ab7; float: right;"></i>
                </div>
                <div class="col-md-6">
                <p style="float: left; margin: 0px;">Drag & Drop files here or click to upload</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6 p-10">
          <button class="btn btn-ornge mr-10"  (click)="validateFile()" >Preview</button>
          <input class="btn btn-gray mr-3" type="button" value="Back" [routerLink]="['/FSM', dataService.customer]">
          <a (click)="generateXcelFile()" class="lnk-download" style="color: #337ab7;"> <i class="fa fa-cloud-download upload" style="color: #337ab7;">
            </i>
            Download Template</a>
          <div class="file-success" *ngIf="fileName">{{ fileName }}</div>
        </div>
      </div>
    </div>
    <div class="row m-none preview-message-count" *ngIf="previewMessage !=''">
      {{previewMessage}}
    </div>
    <div class="table-responsive mt-10">
      <div class="mat-elevation-z8">

        <table mat-table [dataSource]="dataSource"  class="table site-table">
          <ng-container *ngFor="let fields of this.dataService.siteFields" matColumnDef="{{fields}}">
            <th mat-header-cell *matHeaderCellDef >{{fields}}</th>
            <td mat-cell *matCellDef="let siteData"> {{siteData[fields]}} </td>
          </ng-container>
          <ng-container *ngFor="let supplementals of this.dataService.supplementalFields" matColumnDef="{{supplementals}}">
            <th mat-header-cell *matHeaderCellDef >{{supplementals}}</th>
            <td mat-cell *matCellDef="let siteData"> {{siteData[supplementals]}} </td>
          </ng-container>
          <ng-container matColumnDef="Remarks">
            <th mat-header-cell *matHeaderCellDef >Remarks</th>
            <td mat-cell *matCellDef="let siteData" class="error-tooltip">
              {{siteData.message != "" ? "Failed" : ""}}
              <span *ngIf="siteData.message != ''" class="error-tooltip-text">
                <ul class="m-none plr-5">
                  <li class="li-error-message-text" *ngFor="let item of siteData.message.split('|')" [hidden]="item == ''">
                    {{ item }}</li>
                </ul>
              </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="this.dataService.bulkUploadFields"></tr>
          <tr mat-row *matRowDef="let row; columns: this.dataService.bulkUploadFields;" [ngClass]="{'highlight': row.message !== ''}"></tr>
        </table>


        <div *ngIf="displayNoRecords" class="no-recored-found">No result found</div>
        <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
    <div class="btn-groups" *ngIf="perfectRecords != 0">
      <input class="btn btn-gray" type="button" value="Cancel" (click)="clearData()">
      <input class="btn btn-ornge" value="Upload" type="button" (click)="prepareSiteData()">
    </div>
  </div>
</div>
