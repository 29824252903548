import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NgForm} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {google} from "@agm/core/services/google-maps-types";
import {NgxSpinner} from "ngx-spinner/lib/ngx-spinner.enum";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute} from "@angular/router";
import { MapsAPILoader, MouseEvent } from '@agm/core';
import {connectableObservableDescriptor} from "rxjs/internal/observable/ConnectableObservable";
import {DataService} from "../../dataServiceFSMSites/data.service";
import {Site} from "../../interfaceModels/siteInterface";
import {ModalDirective} from "ng-uikit-pro-standard";




export interface Geolocation
{
  Id: string;
  Latitude: number;
  Longitude: number;
}

export interface SitesSupplementalInfo {
  ParentId: string;
  Key: string;
  Value: string;
}

export interface Sites {
  Selected: boolean;
  Id: string;
  Active: boolean;
  Address: string;
  City: string;
  Code: string;
  Country?: any;
  County: string;
  CreatedAtUtc: Date;
  CreatedById: string;
  GeolocationId: string;
  LastSyncedServerUtc: Date;
  Name: string;
  OilMarketingCompany: string;
  Owner: string;
  Phone: string;
  ReplacesId: string;
  State: string;
  SyncType: number;
  TemporarySiteCode: boolean;
  Type: string;
  ZipCode?: any;
  CreatedBy?: any;
  Geolocation: Geolocation;
  Replaces?: any;
  Containers: any[];
  InverseReplaces: any[];
  SiteVisits: any[];
  SitesSupplementalInfo: SitesSupplementalInfo[];
}



@Component({
  selector: 'app-view-sites',
  templateUrl: './view-sites.component.html',
  styleUrls: ['./view-sites.component.scss']
})
export class ViewSitesComponent implements OnInit{

  constructor(private http: HttpClient, public dataService: DataService, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute) {

  }

  public selectedSite: Site =
{
  Active: false,
  Address: "",
  City: "",
  Code: "",
  Country: "",
  County: "",
  CreatedAtUtc: undefined,
  CreatedById: undefined,
  GeolocationId: undefined,
  Id: undefined,
  LastSyncedServerUtc: undefined,
  Name: "",
  OilMarketingCompany: "",
  Owner: "",
  Phone: "",
  ReplacesId: undefined,
  SitesSupplementalInfo: [],
  Geolocation: undefined,
  State: "",
  SyncType: 0,
  TemporarySiteCode: false,
  Type: "",
  ZipCode: ""
};

  zoom: number = 8;
  public customer: string;
  public program: string;
  public mapList: any;
  public duplicates: any;
  public duplicateCount: number;
  public siteList: any;
  formLabels: any;
  public siteSelected: boolean = false;
  public mapview: boolean = false;
  displayedColumns: string[] = ['Code','Name', 'City', 'Address', 'OilMarketingCompany', 'Edit'];
  dataSource: MatTableDataSource<Sites>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('basicModal') public showModalOnClick: ModalDirective;
  toggleName: string = 'Map ';
  HighlightRow: Number;
  showSiteInfoEditPanel = true;
  showSelectSitePanel = true;
  showFullMap = false;
  showHalfMap = false
  showSiteTable = true;
  addLocation = false;
  locationNotIncluded = false;
  expandButtonName: string = 'Expand Map';
  expandIconCss: string = 'fa fa-expand-arrows-alt';
  mapCss : string = 'col-lg-6 gray-bg mt-1 mat-elevation-z8';
  mapSizing : any;
  siteCodeList: any;
  latitude: number;
  longitude: number;
  badCode: boolean;
  allowAdd: boolean;
  mapLat: number;
  mapLong: number;
  configSupplementals: Array<string> = [];

  ngOnInit() {
    this.spinner.show();

      // set data checks and get the customer name from the route
      this.badCode = false;
      this.allowAdd = false;
      this.customer = this.activatedRoute.snapshot.paramMap.get('customer');

      // get a list of the customers sites
    this.dataService.getSites(this.customer).subscribe((data: Sites) => {
      this.siteList = data;

      this.siteList = this.siteList.filter(s => s.Code !== 'Code');

      //map to table and pagation
      this.dataSource = new MatTableDataSource(this.siteList);
      this.dataService._currentSites = new MatTableDataSource(this.siteList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();

      this.setCustomerCoodrinates(this.customer);

      if(this.dataService.customer === undefined) {
        this.dataService._customer = this.customer;

        this.dataService.getDuplicateSiteList(this.customer).subscribe((data: Sites) => {
          this.duplicates = data;

          this.duplicateCount = this.duplicates.length;
          ;
          if (this.duplicates.length > 0)
            this.showModalOnClick.show();

        });
      }
    });

    // Get a list of codes to verify no duplicates get added
    this.dataService.getDistinctSiteCodes(this.customer).subscribe((data: any) => {
      this.siteCodeList = data;
    });

    // if(this.dataService.dbFields.length === 0)
    // {
    //   // Grab the form layout from the db
    //   this.dataService.getProgramConfig(this.customer).subscribe((data: any) => {
    //     this.dataService._dbFields = data;
    //   });
    // }

    if(!this.dataService.formStructure) {
      this.dataService.getVisuals(this.customer).subscribe((data: any) => {
        this.formLabels = data;
        this.dataService._formStructure = this.formLabels;

        for (let label in this.formLabels) {

          if (this.formLabels[label] !== 'Hidden') {

            if(label === 'OilMarketingCompany')
              label = 'Oil Marketing Company';

            if(label !== 'Supplemental')
            {
              this.dataService._siteFields.push(label);
              this.dataService._bulkUploadFields.push(label);
            }
          }
        }

        for (const label in this.formLabels.Supplemental) {
          if (this.formLabels.Supplemental[label].Policy !== 'Hidden') {
            this.selectedSite.SitesSupplementalInfo.push({ParentId: undefined, Key: this.formLabels.Supplemental[label].Name, Value: null });
            this.configSupplementals.push(this.formLabels.Supplemental[label].Name);
            this.dataService._supplementalFields.push(this.formLabels.Supplemental[label].Name);
            this.dataService._bulkUploadFields.push(this.formLabels.Supplemental[label].Name);
          }
        }
      });
    }
    else{
      console.log(this.dataService.supplementalFields);
      for (let i = 0; i < this.dataService.supplementalFields.length; i++) {
          console.log(this.dataService.supplementalFields[i]);
          this.selectedSite.SitesSupplementalInfo.push({ParentId: undefined, Key: this.dataService.supplementalFields[i], Value: null });
      }
    }



  }

  checkCode(value){
    let item


    // Check if the code typed in already exist
    for (let i = 0; i < this.siteCodeList.length; i++) {
      if (this.siteCodeList[i] === value) {
        item = this.siteCodeList[i]
        this.badCode = true;
        break;
      }
      this.badCode = false;
    }

    // Make sure code is not empty or duplicate to allow add of new record
    if(value.length > 0 && this.badCode === false)
    {
      this.allowAdd = true;
    }
    else
      this.allowAdd = false;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  dontVerifySites(){
    this.showModalOnClick.hide();
  }

  public populate(site: any) {

    this.showFullMap = false;
    this.showHalfMap = false;
    this.showSiteInfoEditPanel = true;
    this.showSelectSitePanel = false;
    this.showSiteTable = false;
    this.addLocation = true;
    this.latitude = site.Geolocation.Latitude;
    this.longitude = site.Geolocation.Longitude;
    this.zoom = 16;
    this.selectedSite = site;
  }

  public populateEditFromTable(site: any) {


    this.showSelectSitePanel = false;

    if (site.Geolocation === null)
    {
      this.locationNotIncluded = true;
      this.showSiteTable = false;
    }
    else {
      this.showSiteTable = false;
      this.addLocation = true;
      this.latitude = site.Geolocation.Latitude;
      this.longitude = site.Geolocation.Longitude;
      this.zoom = 16;
    }

    this.selectedSite = site;

    console.log(this.selectedSite);

    this.HighlightRow = site.Id;



    // Check for new supplemental fields and if they want to add them to records

    // For each supplemental in the current program config
    for(let x = 0; x < this.dataService.formStructure.Supplemental.length; x++)
    {
      let match = false;

      // For each supplemental that the selected site has
      for(let i = 0; i < this.selectedSite.SitesSupplementalInfo.length; i++)
      {

        // Check if it matches the current
        if(this.selectedSite.SitesSupplementalInfo[i].Key === this.dataService.formStructure.Supplemental[x].Name)
        {
          match = true;
        }
      }

        // No match means the record is missing new supplementals
      if(match === false)
      {
        this.selectedSite.SitesSupplementalInfo.push({ParentId: this.selectedSite.Id, Key: this.dataService.formStructure.Supplemental[x].Name, Value: null});
      }


    }
  }

  selectNewLocation() {
    this.showSiteTable = false;
    this.locationNotIncluded = false;
    this.addLocation = true;
    this.setCurrentLocation();


  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        if(this.selectedSite.Geolocation === null) {
          this.selectedSite.Geolocation = {Id: undefined, Latitude: position.coords.latitude, Longitude: position.coords.longitude}
        }
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.selectedSite.Geolocation.Latitude = $event.coords.lat;
    this.selectedSite.Geolocation.Longitude = $event.coords.lng;
  }

  public updateSite(site: Site){

    this.spinner.show();

    this.dataService.updateSite(site, this.customer)
        .then(res => {
          this.siteSelected = false;
          this.ngOnInit();
          this.clearForm();
          this.showSelectSitePanel = true;
          this.siteSelected = false;
        });
  }

  public addSite(site: any){
    site.Active = true;
    this.spinner.show();
    this.dataService.addSite(site,this.customer)
        .then(res => {
          this.ngOnInit();
          this.clearForm();
          this.showSelectSitePanel = true;
          this.siteSelected = false;
        });
  }


  public toggleMapOff(){

    if(this.mapview === false) {
      this.showSiteTable = false;
      this.mapview = true;
      this.showHalfMap = true;
      this.zoom = 7;
      this.toggleName = ' List ';
    }
    else {
      this.mapview = false;
      this.showHalfMap = false;
      this.showSiteTable = true;
      this.toggleName = ' Map ';
    }

    this.clearForm();
  }



  public clearForm(){

    console.log(this.toggleName);

    if(this.toggleName === ' List ') {
      this.zoom = 7;
      this.mapview = true;
      this.showHalfMap = true;
      this.showSiteTable = false;
    }
    else {
      this.showSiteTable = true;
      this.showHalfMap = false;
    }


    this.addLocation = false;
    this.showSiteInfoEditPanel = true;
    this.showSelectSitePanel = true;
    this.locationNotIncluded = false;


    this.selectedSite =
      {
        Active: false,
        Address: "",
        City: "",
        Code: "",
        Country: "",
        County: "",
        CreatedAtUtc: undefined,
        CreatedById: undefined,
        GeolocationId: undefined,
        Id: undefined,
        LastSyncedServerUtc: undefined,
        Name: "",
        OilMarketingCompany: "",
        Owner: "",
        Phone: "",
        ReplacesId: undefined,
        SitesSupplementalInfo: [],
        Geolocation: undefined,
        State: "",
        SyncType: 0,
        TemporarySiteCode: false,
        Type: "",
        ZipCode: ""
      };

    for(let i=0; i<this.configSupplementals.length; i++){
      this.selectedSite.SitesSupplementalInfo.push({ParentId:undefined
        , Key: this.configSupplementals[i], Value: null });
    }
  }

setCustomerCoodrinates(customer: string) {

    switch (customer) {
      case 'Kenya':
        this.mapLat = -0.023559;
        this.mapLong = 37.906193;
        break;

      case 'Ghana':
        this.mapLat = 7.946527;
        this.mapLong = -1.023194;
        break;
    }

  }

}
