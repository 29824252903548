<div class="top-title-bar">
  <a class="adjustments">Edit Order</a>
</div>

<ngx-spinner [fullScreen]="true" bdColor="rgba(51,51,51,0.8)" size="medium"  color="#dd7322" type="ball-spin-clockwise-fade">
</ngx-spinner>

<div class="gray-bg mt-1">


  <div class="gray-bg mt-1">
    <div class="gray-box-head">
      <h2>General Information</h2>
    </div>

    <div>

      <div class="row">

        <div class=" col-md-3">
          <div class="form-field-row">
            <label class="form-label">Order ID</label>
            <div class="input-field">
              <input [(ngModel)]="this.dataService.foreignKeys.OrderUniqueId" readonly type="text">
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-field-row">
            <label class="form-label">Order Number</label>
            <div class="input-field">
              <input [(ngModel)]="this.dataService.foreignKeys.OrderNumber" readonly type="text">
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-field-row">
            <label class="form-label">Depot</label>
            <div class="input-field">
             <input [(ngModel)]="this.dataService.foreignKeys.DepotName" readonly type="text">
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label>Order Date</label>
          <mat-form-field style="width: 100% !important; border: #c9cbcf solid 1px;">
            <input [(ngModel)]="fullOrder.OrderDate" [matDatepicker]="picker1"  matInput>
            <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>

      </div>


      <div class="row">

        <div class="col-md-3">
          <label>Processed Date</label>
          <mat-form-field style="width: 100% !important;">
            <input [(ngModel)]="fullOrder.OrderProcDate" [matDatepicker]="picker2" matInput>
            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>

        <div class=" col-md-3">
          <div class="form-field-row">
            <label class="form-label">Depot Tag Number</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.DepotTagNumber" type="text">
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="form-label">Location</label>
          <ng-select [(ngModel)]="fullOrder.DestinLocName"
                     [items]="this.dataService.dropDowns.desLocations"
                     [virtualScroll]="true"
                     bindLabel="name"
                     bindValue="name"
                     placeholder="Select Location">
          </ng-select>
        </div>

        <div class="col-md-3">
          <label>Status</label>
          <ng-select [(ngModel)]="fullOrder.OrderStatusId"
                     [items]="statuses"
                     [virtualScroll]="true"
                     bindLabel="name"
                     bindValue="id"
                     placeholder="Select Status"
                     >
          </ng-select>
        </div>

      </div>

      <div class="row">

        <div class="col-md-3">
          <label>OMC</label>
          <ng-select [(ngModel)]="fullOrder.OmcId"
                     [items]="editResources.OmcList"
                     [virtualScroll]="true"
                     bindLabel="Name"
                     bindValue="Id"
                     placeholder="Select OMC"
          >
          </ng-select>
        </div>

        <div class="col-md-3">
          <label>BDC</label>
          <ng-select [(ngModel)]="fullOrder.BdcId"
                     [items]="editResources.BdcList"
                     [virtualScroll]="true"
                     bindLabel="Name"
                     bindValue="Id"
                     placeholder="Select BDC"
          >
          </ng-select>
        </div>

        <div class="col-md-3">
          <label>Transporter</label>
          <ng-select [(ngModel)]="fullOrder.TransporterId"
                     [items]="this.dataService.dropDowns.transporters"
                     [virtualScroll]="true"
                     bindLabel="name"
                     bindValue="id"
                     placeholder="Select Transporter"
          >
          </ng-select></div>

        <div class="col-md-3">
          <label>Vehicle Id #</label>
          <ng-select [(ngModel)]="fullOrder.TankerHeadNumber"
                     [items]="dataService.dropDowns.tankerHeadId"
                     [virtualScroll]="true"
                     bindLabel="name"
                     bindValue="name"
                     placeholder="Select Vehicle Id"
          >
          </ng-select>
        </div>

      </div>

      <div class="row" style="margin-top: 5px;">

        <div class="col-md-3">
          <label>Driver</label>
          <ng-select [(ngModel)]="fullOrder.DriverName"
                     [items]="dataService.dropDowns.driverName"
                     [virtualScroll]="true"
                     bindLabel="name"
                     bindValue="name"
                     placeholder="Select Driver"
          >
          </ng-select>
        </div>

        <div class=" col-md-3">
          <div class="form-field-row">
            <label class="form-label">Customer</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.CustomerName" type="text">
            </div>
          </div>
        </div>

      </div>
  </div>
</div>

  <div class="gray-bg mt-1">
    <div class="gray-box-head">
      <h2>Compartments</h2>
    </div>

    <div class="white-box">
      <div class="example-container-compartment mat-elevation-z8">
        <ngx-spinner [fullScreen]="true" bdColor="rgba(51,51,51,0.8)" size="medium"  color="#dd7322" type="ball-spin-clockwise-fade">
        </ngx-spinner>
        <div class="table-responsive">

          <table mat-table [dataSource]="editResources.Compartments" class="table site-table">

            <!-- Number Column -->
            <ng-container matColumnDef="number">
              <th mat-header-cell *matHeaderCellDef>Number</th>
              <td mat-cell *matCellDef="let row">{{row.Number}}</td>
            </ng-container>

            <!-- Product Name Column -->
            <ng-container matColumnDef="productName">
              <th mat-header-cell *matHeaderCellDef>Product Name</th>
              <td mat-cell *matCellDef="let row">{{row.ProductName}}</td>
            </ng-container>

            <!-- Product Type Column -->
            <ng-container matColumnDef="productType">
              <th mat-header-cell *matHeaderCellDef>Product Type</th>
              <td mat-cell *matCellDef="let row">{{row.ProductType}}</td>
            </ng-container>

            <!-- Product Quantity Column -->
            <ng-container matColumnDef="productQuantity">
              <th mat-header-cell *matHeaderCellDef>Product Quantity</th>
              <td mat-cell *matCellDef="let row">{{row.ProductQuantity}}</td>
            </ng-container>

            <!-- Marker Name Column -->
            <ng-container matColumnDef="markerName">
              <th mat-header-cell *matHeaderCellDef>Marker Name</th>
              <td mat-cell *matCellDef="let row">{{row.MarkerName}}</td>
            </ng-container>

            <!-- Marker Quantity Column -->
            <ng-container matColumnDef="markerQuantity">
              <th mat-header-cell *matHeaderCellDef>Marker Quantity</th>
              <td mat-cell *matCellDef="let row">{{row.MarkerQuantity}}</td>
            </ng-container>


              <!-- Edit Record Column-->
              <ng-container matColumnDef="editCompartment">
                <th mat-header-cell *matHeaderCellDef>Edit</th>
                <td mat-cell *matCellDef="let row">
                  <ul class="table-actions">
                    <li class="pencil" [routerLink]="['editCompartment/']" (click)="passCompartment(row)">
                      <i matTooltip="Edit Compartment" matTooltipPosition="above"
                         class="fa fa-pencil"></i></li>
                  </ul>
                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

      </div>
    </div>
  </div>




    <div class="gray-bg mt-1">
      <div class="gray-box-head">
        <input _ngcontent-c18="" class="btn btn-gray" [routerLink]="['/FMM/Ghana']" type="button" value="Cancel">
        <input
          (click)="updateOrder()"
          class="btn btn-ornge float-right" type="button" value="Update">
      </div>
    </div>




