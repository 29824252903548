<div class="top-title-bar">
  <a class="adjustments">Edit Compartment</a>
</div>

<ngx-spinner [fullScreen]="true" bdColor="rgba(51,51,51,0.8)" size="medium"  color="#dd7322" type="ball-spin-clockwise-fade">
</ngx-spinner>

<!--Compartment Info-->
<div class="gray-bg mt-1">
  <div class="gray-bg mt-1">
    <div class="gray-box-head">
      <h2>General Information</h2>
    </div>
    <div>
      <!-- Row 1 -->
      <div class="row">
        <!-- Compartment Number -->
        <div class="col-lg-3 col-md-3 col-sm-6">
          <div class="form-field-row">
            <label class="form-label">Number</label>
            <div class="input-field">
              <input type="text" [(ngModel)]="compartmentEdit.Number" readonly>
            </div>
          </div>
        </div>
        <!-- Compartment Product -->
        <div class="col-lg-3 col-md-3 col-sm-6">
          <div class="form-field-row">
            <label class="form-label">Product</label>
            <ng-select (change)="productChange()" [(ngModel)]="compartmentEdit.ProductId"
                       [items]="this.dataService.products"
                       [virtualScroll]="true"
                       bindLabel="Name"
                       bindValue="Id"
                       placeholder="Select a product"
            >
            </ng-select>
            <div class="select-field">
            </div>
          </div>
        </div>
        <!-- Compartment Product Type -->
        <div class="col-lg-3 col-md-3 col-sm-6">
          <div class="form-field-row">
            <label class="form-label">Product Type</label>
            <div class="input-field">
              <input type="text" [(ngModel)]="compartmentEdit.ProductType"  readonly>
            </div>
          </div>
        </div>
        <!-- Compartment Product Quantity -->
        <div class="col-lg-3 col-md-3 col-sm-6">
          <div class="form-field-row">
            <label class="form-label">Product Quantity</label>
            <div class="input-field">
              <input (input)="productChange()" [(ngModel)]="compartmentEdit.ProductQuantity"  type="text" >
            </div>
          </div>
        </div>
      </div>
      <!-- Row 2 -->
      <div class="row">
        <!-- Compartment Marker -->
        <div class="col-lg-3 col-md-3 col-sm-6">
          <div class="form-field-row">
            <label class="form-label">Marker</label>
            <div class="input-field">
              <input type="text" [(ngModel)]="compartmentEdit.MarkerName" readonly>
            </div>
          </div>
        </div>
        <!-- Marker Quantity -->
        <div class="col-lg-3 col-md-3 col-sm-6">
          <div class="form-field-row">
            <label class="form-label">Marker Quantity</label>
            <div class="input-field">
              <input type="text" [(ngModel)]="compartmentEdit.MarkerQuantity"  readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="gray-bg mt-1">
    <div class="gray-box-head">
      <input _ngcontent-c18="" class="btn btn-gray" type="button" value="Cancel" [routerLink]="['//FMM/Ghana/editOrder/',this.dataService.foreignKeys.OrderId]">
      <input  class="btn btn-ornge float-right" type="button" value="Update" (click)="updateCompartment()">
    </div>
  </div>

</div>
