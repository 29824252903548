import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {UserDataService} from '../../usersDataService/user-data.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ModalDirective} from 'ng-uikit-pro-standard';
import {Sites} from '../../../SiteManagment/components/view-sites/view-sites.component';
import {any} from 'codelyzer/util/function';


export interface Users {

  Id: string;
  Active: boolean;
  FirstName: string;
  IsHidden: boolean;
  LastName: string;
  LastSyncedServerUtc: Date;
  PhotoBytes: string;
  PincodeHash: string;
  Salt: string;
  SyncType: number;
  UserTypeId: string;
  UserName: string;
  UserType: number;
}

@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.scss']
})
export class ViewUsersComponent implements OnInit {

  constructor(private http: HttpClient, public dataService: UserDataService, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute) { }

  public selectedUser: any =
    {
      Id: undefined,
      Active: true,
      FirstName: '',
      IsHidden: undefined,
      LastName: '',
      LastSyncedServerUtc: undefined,
      PhotoBytes: undefined,
      PincodeHash: '',
      ResetPin: true,
      Salt: '',
      SyncType: 0,
      UserTypeId: '',
      Username: '',
      UserType: {
        Id: '',
        Active: undefined,
        LastSyncedServerUtc: undefined,
        Level: undefined,
        Name: '',
        PermissionMask: undefined,
        PluralName: '',
        SyncType: undefined,
        UserManagementPermissionMask: undefined,
        Users: []
      }
    };

  public userList: any;
  public userTypes: any;
  public customer: string;
  displayedColumns: string[] = ['FirstName', 'LastName', 'Username', 'UserType', 'Active', 'Edit'];
  dataSource: MatTableDataSource<Users>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  allowAdd = true;
  userSelected = false;


  ngOnInit(): void {

    this.customer = this.activatedRoute.snapshot.paramMap.get('customer');

    this.dataService._customer = this.customer;

    // Load page and get table values
    this.getUsers();
    this.getUserTypes();
  }

  public populateEditFromTable(user: Users){
    this.selectedUser = user;
    this.userSelected = true;
  }

  public getUsers(){

    this.spinner.show();

    // get a list of the users
    this.dataService.getUsers(this.customer).subscribe((data: Users) => {
      this.userList = data;
      this.dataService._userList = data;
      // map to table and pagation
      this.dataSource = new MatTableDataSource(this.userList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    });

  }

  public getUserTypes(){

    this.dataService.getUserTypes(this.customer).subscribe((data: any) => {
      this.userTypes = data;
      this.dataService._userTypes = data;
    });

  }

  public addNewUser(){

    this.spinner.show();

    this.selectedUser.UserType.Id = this.selectedUser.UserTypeId;

    this.dataService.addNewUser(this.selectedUser, this.customer)
      .then(res => {
        this.userSelected = false;
        this.getUsers();
        this.clearForm();
      });

  }

  public updateUser(){

    this.spinner.show();

    this.selectedUser.UserType.Id = this.selectedUser.UserTypeId;

    //check for a pin reset

    if (this.selectedUser.ResetPin === true)
    {
      this.selectedUser.PincodeHash = '';
      this.selectedUser.Salt = '';
    }

    this.dataService.updateUser(this.selectedUser, this.customer)
      .then(res => {
        this.userSelected = false;
        this.getUsers();
        this.clearForm();
      });
  }

  public clearForm() {

    this.userSelected = false;

    this.selectedUser =
      {
        Id: undefined,
        Active: true,
        FirstName: '',
        IsHidden: undefined,
        LastName: '',
        LastSyncedServerUtc: undefined,
        PhotoBytes: undefined,
        PincodeHash: '',
        ResetPin: true,
        Salt: '',
        SyncType: 0,
        UserTypeId: '',
        Username: '',
        UserType: {
          Id: '',
          Active: undefined,
          LastSyncedServerUtc: undefined,
          Level: undefined,
          Name: '',
          PermissionMask: undefined,
          PluralName: '',
          SyncType: undefined,
          UserManagementPermissionMask: undefined,
          Users: []
        }

      };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
