import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../dataServiceMoeKsaASTM/data.service';
import {PropertyListDefault} from '../../interfaceModels/propertyListDefault';
import {SingleSampleInterfaceModel} from '../../interfaceModels/singleSampleInterface.model';

@Component({
  selector: 'app-add-sample',
  templateUrl: './moe-ksa-astm-add-sample.component.html',
  styleUrls: ['./moe-ksa-astm-add-sample.component.scss']
})
export class MoeKsaAstmAddSampleComponent implements OnInit {


  constructor(public service: DataService, private router: Router) { }

  editField: string;
  propertyList: any = [];
  tempProperty: PropertyListDefault;
  sample: SingleSampleInterfaceModel;

  register(form) {

    // create sample first
    this.service.addNewSample(form.value)
      .then(res => {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < this.propertyList.length; i++) {
              this.propertyList[i].parentId = this.service.parentId;
            }
            console.log(this.propertyList);
            resolve();
          }
        );
      }).then(res => {
          this.service.addNewProperties(this.propertyList);
          this.router.navigate(['/ASTM/MOE']);
    });

  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.propertyList[id][property] = editField;
  }

  remove(id: any) {
    this.propertyList.splice(id, 1);
  }

  add() {

      const newProperty = {key: 'New Property', value: '0', methodUsed: 'ASTM', units: '--'};
      this.propertyList.unshift(newProperty);

  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }

  ngOnInit(): void {
    const noDoubles: any = [];

    this.service.getDefaultProperties().subscribe((data: PropertyListDefault) => {
      this.propertyList = data;
      this.propertyList.forEach(function(value) {
        value.value = '0';
        value.methodUsed = 'ASTM';
        value.units = '--';
      });
    });
  }
}
