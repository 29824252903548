import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe, formatDate} from '@angular/common';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {DataService} from '../../dataServiceMoeKsaASTM/data.service';
import {SingleSampleInterfaceModel} from '../../interfaceModels/singleSampleInterface.model';


@Component({
  selector: 'app-view-samples',
  templateUrl: './moe-ksa-astm-view-samples.component.html',
  styleUrls: ['./moe-ksa-astm-view-samples.component.scss']
})
export class MoeKsaAstmViewSamplesComponent implements AfterViewInit {
  data: any = [];
  displayedColumns: string[] = ['Sample', 'Type', 'Description', 'ReportDate', 'SampleDate', 'AnalyzedDate', 'Location', 'Edit'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dataService: DataService, public datepipe: DatePipe) { }

  passSample(sample: SingleSampleInterfaceModel) {

    const sample_date = this.datepipe.transform(sample.sampleDate, 'yyyy-MM-dd');
    sample.sampleDate = sample_date;

    const report_date = this.datepipe.transform(sample.reportDate, 'yyyy-MM-dd');
    sample.reportDate = report_date;

    const received_date = this.datepipe.transform(sample.sampleReceivedDate, 'yyyy-MM-dd');
    sample.sampleReceivedDate = received_date;

    const analyzed_date = this.datepipe.transform(sample.sampleAnalyzedDate, 'yyyy-MM-dd');
    sample.sampleAnalyzedDate = analyzed_date;


    this.dataService._sampleEdit = sample;

  }

  ngAfterViewInit() {

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.dataService.getSamples(this.paginator.pageIndex, this.paginator.pageSize, this.dataService.sampleNameFilter, formatDate(this.dataService.dateFilter,'MM/dd/yyyy', 'en'));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.count;
          console.log(data);
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  // Applies new selected filters to query and fetches updated results.
  updateTable(){
    this.paginator.pageIndex =  0;
    this.isLoadingResults = true;
    this.dataService.getSamples(this.paginator.pageIndex, this.paginator.pageSize, this.dataService.sampleNameFilter, formatDate(this.dataService.dateFilter,'MM/dd/yyyy', 'en'))
      .pipe(
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.count;
          this.isRateLimitReached = false;
          if(this.resultsLength == 0)
          {
            this.isRateLimitReached = true;
          }

          if(this.dataService.dateFilter == null)
          {
            console.log(data);
            this.dataService._dateFilter = new Date(data.listResult[0].reportDate);
          }
          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the API has reached its rate limit or returns empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }
}


