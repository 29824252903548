import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {DropDownApi} from '../interfaceModels/dropDownApi';
import {Order} from '../interfaceModels/order.model';
import {Compartments} from '../interfaceModels/compartments';
import {Products} from '../interfaceModels/products';
import {OrderFetchApi} from '../interfaceModels/orderFetchApi';
import {EditResourcesApi} from '../interfaceModels/editResourcesApi';
import {FullOrderInterface} from '../interfaceModels/fullOrderInterface';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  apiUrl = 'https://atx-kenya-erdms-dev.azurewebsites.net/api/';

  // Array Variable to help us not make so many data calls and preserve the list throughout the session

  _dropDowns: DropDownApi;
  set dropDowns(value: DropDownApi){
    this._dropDowns = value;
  }
  get dropDowns(): DropDownApi {
    return this._dropDowns;
  }

  _foreignKeys: Order;
  set passedOrder(value: Order){
    this._foreignKeys = value;
  }
  get foreignKeys(): Order {
    return this._foreignKeys;
  }

  _compartment: Compartments;
  set compartment(value: Compartments){
    this._compartment = value;
  }
  get compartment(): Compartments {
    return this._compartment;
  }

  _products: Products[];
  set products(value: Products[]){
    this._products = value;
  }
  get products(): Products[] {
    return this._products;
  }


  // The filter data is stored in this layer so it remains static throughout the session. Saves the user alot of time.
  _dateFilter: Date = null;
  set dateFilter(value: Date){
    this._dateFilter = value;
  }
  get dateFilter(): Date {
    return this._dateFilter;
  }

  _depotFilter = '';
  set depotFilter(value: string){
    this._depotFilter = value;
  }
  get depotFilter(): string {
    return this._depotFilter;
  }

  _orderNumberFilter = '';
  set orderNumberFilter(value: string){
    this._orderNumberFilter = value;
  }
  get orderNumberFilter(): string {
    return this._orderNumberFilter;
  }

  _orderNameFilter = '';
  set orderNameFilter(value: string){
    this._orderNameFilter = value;
  }
  get orderNameFilter(): string {
    return this._orderNameFilter;
  }



  constructor(private http: HttpClient, private router: Router, private spinner: NgxSpinnerService) { }

  // HTTP CALLS


  // GETS
  public getOrders(date: string, depot: string, orderNumber: string, orderName: string, page: number, pageSize: number): Observable<OrderFetchApi> {

    // This if statment is used to determine if the user left the date blank. For some reason if its blank it defaults to the date in question and it messes up the api call.
    if (date === '12/31/1969')
    {
      date = '';
    }

    const fullUrl = `${this.apiUrl}GetOrderList?code=RSAtZUn2aFcK21L9HcYpKnPoFc6vEI9PfUEi/KB8/FevL/j2EcfuXw==&startDate=${date}&endDate=${date}&depotName=${depot}&orderNumber=${orderNumber}&orderName=${orderName}&pageNumber=${page + 1}&pageSize=${pageSize}`;
    return this.http.get<OrderFetchApi>(fullUrl);
  }

  public getDropDowns(): Observable<DropDownApi>{

    const fullUrl = `${this.apiUrl}GetDropdownLists?code=wslX1JAy7HhCaN8lCpbXSU2E9S/I54Uz8CbaDKmSRKKfPm6Vn2OAxg==`;
    return this.http.get<DropDownApi>(fullUrl);
  }

  public getEditResources(id: string): Observable<EditResourcesApi>{
    const fullUrl = `${this.apiUrl}GetEditResources?code=hfRKeWYfmo14n97/aiH8PB9/ts7uq7m9yJQDCjNZPDNhTKstep57VQ==&orderId=${id}`;
    return this.http.get<EditResourcesApi>(fullUrl);
  }

  public getSingleOrder(id: string): Observable<FullOrderInterface>{
    const fullUrl = `${this.apiUrl}GetOrder?code=h6F5GIoR1jMGAh9qJ4kj8/Goy7qBwLFrhwhhD8S9GqJ8SVa4ll/6cw==&id=${id}`;
    return this.http.get<FullOrderInterface>(fullUrl);
  }

// SAVES

  public updateOrder(order: FullOrderInterface){
     const fullUrl = `${this.apiUrl}SaveOrder?code=MP0EamJ3YgYEdj3oEbrquezz/PDQe9HdLdl04gHbGx12Vw53XeY5pg==`;
     return this.http.post<FullOrderInterface>(fullUrl, order)
       .subscribe(
         res => {
           console.log(res);
           this.router.navigate(['/FMM/Kenya']);
         },
          err => {
           console.log('error');
          }
       );
  }

  public updateCompartment(compartment: Compartments){
    this.spinner.show();
    const fullUrl = `${this.apiUrl}SaveCompartments?code=ivIbmDntFc2eCS4dysgvqse811HGHzvMKKKj3ldoF9Fl068aMlpRMg==`;
    return this.http.post<Compartments>(fullUrl, [compartment])
      .subscribe(
        res => {
          console.log(res);
          this.spinner.hide();
          this.router.navigate(['//FMM/Kenya/editOrder/' + this.foreignKeys.OrderId]);
        },
        err => {
          console.log('error');
          this.spinner.hide();
          this.router.navigate(['//FMM/Kenya/editOrder/' + this.foreignKeys.OrderId]);
        }
      );
  }

}

