import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {SingleSampleInterfaceModel} from '../interfaceModels/singleSampleInterface.model';
import {SampleFetchApi} from '../interfaceModels/SampleFetchApi';
import {PropertyListDefault} from '../interfaceModels/propertyListDefault';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  _sampleEdit: SingleSampleInterfaceModel;
  set passedOrder(value: SingleSampleInterfaceModel) {
    this._sampleEdit = value;
  }
  get sampleEdit(): SingleSampleInterfaceModel {
    return this._sampleEdit;
  }

  _sampleNameFilter: string = '';
  set sampleNameFilter(value: string){
    this._sampleNameFilter = value;
  }
  get sampleNameFilter(): string {
    return this._sampleNameFilter
  }

  _dateFilter: Date = null;
  set dateFilter(value: Date){
    this._dateFilter = value;
  }
  get dateFilter(): Date {
    return this._dateFilter
  }

  apiUrl = 'https://ksamoeapi20200911130139.azurewebsites.net/api/';

  parentId;

  constructor(private http: HttpClient, private router: Router) { }

  // Http Calls

  public getSamples(page: number, pageSize: number, sampleName: string, date: string): Observable<SampleFetchApi> {

    if (date === '12/31/1969')
    {
      date = '';
    }

    const fullUrl = `${this.apiUrl}GetSamples?code=jXiNkRNkD/x4QQ97aPFnZK7yZQpMsvtaxmN0zrwRU2HeMHjnzfXK5g==&pageNumber=${page + 1}&pageSize=${pageSize}&serial=${sampleName}&reportDate=${date}`;

    console.log(fullUrl);
    return this.http.get<SampleFetchApi>(fullUrl);
  }

  public getDefaultProperties(): Observable<PropertyListDefault> {
    const fullUrl = `${this.apiUrl}GetAllProperties?code=ITT1UxKkAoC1YhsgBhelqu4KzKh99j/Fugx0FEytpQRiTg5xiUcQKw==`;
    return this.http.get<PropertyListDefault>(fullUrl);
  }

  public getSampleProperties(id: string): Observable<PropertyListDefault> {
    const fullUrl = `${this.apiUrl}GetSampleProperties?code=JPOYNBdsgeAcsuIk4HUpgrvQTe7DZE4Lxb6Kl5svKLnaHPtxmmP5aQ==&Id=${id}`;
    return this.http.get<PropertyListDefault>(fullUrl);
  }

   addNewSample(sample: SingleSampleInterfaceModel) {
     return new Promise((resolve, reject) => {

       const fullUrl = `${this.apiUrl}AddSample?code=9N2vlPSI2CRU//F9gAgqbxnklI486GC3XFTclLw3aDsNfr0lRADhqA==`;

       console.log(sample);

       const httpOptions = {
         headers: new HttpHeaders({
           ReturnType: 'text'
         })
       };

       return this.http.post<SingleSampleInterfaceModel>(fullUrl, sample, httpOptions)
         .subscribe(
           res => {
             this.parentId = res;
             console.log('Inside Subscribe');
             resolve();
           },
           err => {
             console.log('We didnt make it');
             this.parentId = err.error.text;
             console.log(this.parentId);
             resolve();
           }
         );
     });
  }

  public addNewProperties(properties: PropertyListDefault[]) {
    const fullUrl = `${this.apiUrl}AddSampleProperties?code=ISial5b8Yan69tSzXpRR8tynf6k/SiNOPJgedTMDlt3Slr7OvLkKnQ==`;

    console.log(properties);

    return this.http.post<PropertyListDefault>(fullUrl, properties)
      .subscribe(
        res => {
          console.log(res.value);
        },
        err => {
          console.log('error');
        }
      );
  }

  deleteProperty(id: number): Observable<{}> {
    const url = `https://ksamoeapi20200911130139.azurewebsites.net/api/DeleteSampleProperty?code=TvCyRQLRcVsmKIQl5iRW9SaJFSsf2zuaKHjQW25ZCLxwDVND3i69fg==&Id=${id}`; // DELETE
    return this.http.delete(url);
  }

  updateSample(sample: SingleSampleInterfaceModel) {
    return this.http.put<SingleSampleInterfaceModel>('https://ksamoeapi20200911130139.azurewebsites.net/api/UpdateSample?code=UZeLH73KGjjg/lrwruV2SwtOCM/tz00Q3bebCKnocY/vzZ8TYwOlaw==', sample)
      .subscribe(
        res => {
          console.log(res);

        },
        err => {
          console.log('error');
          this.router.navigate(['/ASTM/MOE']);
        }
      );
  }




}
