
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import {DemoMaterialModule} from './material-module';
import {AppRoutingModule} from './app-routing.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {GhanaFmmGetOrdersComponent} from '../programs/FMM/Ghana/components/get-orders/ghana-fmm-get-orders.component';
import {GhanaFmmEditOrderComponent} from '../programs/FMM/Ghana/components/edit-order/ghana-fmm-edit-order.component';
import {GhanaFmmEditCompartmentComponent} from '../programs/FMM/Ghana/components/edit-compartment/ghana-fmm-edit-compartment.component';
import {KenyaFmmGetOrdersComponent} from '../programs/FMM/Kenya/components/get-orders/kenya-fmm-get-orders.component';
import {KenyaFmmEditOrderComponent} from '../programs/FMM/Kenya/components/edit-order/kenya-fmm-edit-order.component';
import {KenyaFmmEditCompartmentComponent} from '../programs/FMM/Kenya/components/edit-compartment/kenya-fmm-edit-compartment.component';
import {ZambiaAstmViewSamplesComponent} from '../programs/ASTM/Zambia/components/view-samples/zambia-astm-view-samples.component';
import {ZambiaAstmEditSampleComponent} from '../programs/ASTM/Zambia/components/edit-sample/zambia-astm-edit-sample.component';
import {ZambiaAstmAddSampleComponent} from '../programs/ASTM/Zambia/components/add-sample/zambia-astm-add-sample.component';
import {KsaAstmViewSamplesComponent} from '../programs/ASTM/KingdomSaudiArabia/components/view-samples/ksa-astm-view-samples.component';
import {KsaAstmAddSampleComponent} from '../programs/ASTM/KingdomSaudiArabia/components/add-sample/ksa-astm-add-sample.component';
import {KsaAstmEditSampleComponent} from '../programs/ASTM/KingdomSaudiArabia/components/edit-sample/ksa-astm-edit-sample.component';
import {MoeKsaAstmViewSamplesComponent} from '../programs/ASTM/MinistryOfEnergyKSA/components/view-samples/moe-ksa-astm-view-samples.component';
import {MoeKsaAstmAddSampleComponent} from '../programs/ASTM/MinistryOfEnergyKSA/components/add-sample/moe-ksa-astm-add-sample.component';
import {MoeKsaAstmUpdateSampleComponent} from '../programs/ASTM/MinistryOfEnergyKSA/components/update-sample/moe-ksa-astm-update-sample.component';
import {DatePipe} from '@angular/common';
import { DuplicateSiteWizardComponent } from '../programs/FSM/DuplicateSiteConsolidation/duplicate-site-wizard/duplicate-site-wizard.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {AuthGuard} from './auth.guard';
import {ViewSitesComponent} from "../programs/FSM/SiteManagment/components/view-sites/view-sites.component";
import {BulkSiteAddComponent} from "../programs/FSM/SiteManagment/components/bulk-site-add/bulk-site-add.component";
import {DragDropDirective} from "../programs/FSM/SiteManagment/components/bulk-site-add/drag-drop.directive";
import { ViewUsersComponent } from '../programs/FSM/UserManagment/components/view-users/view-users.component';
import { UserBulkUploadComponent } from '../programs/FSM/UserManagment/components/user-bulk-upload/user-bulk-upload.component';

@NgModule({
  declarations: [
    AppComponent,
    GhanaFmmGetOrdersComponent,
    GhanaFmmEditOrderComponent,
    GhanaFmmEditCompartmentComponent,
    KenyaFmmGetOrdersComponent,
    KenyaFmmEditOrderComponent,
    KenyaFmmEditCompartmentComponent,
    ZambiaAstmViewSamplesComponent,
    ZambiaAstmEditSampleComponent,
    ZambiaAstmAddSampleComponent,
    KsaAstmViewSamplesComponent,
    KsaAstmAddSampleComponent,
    KsaAstmEditSampleComponent,
    MoeKsaAstmViewSamplesComponent,
    MoeKsaAstmAddSampleComponent,
    MoeKsaAstmUpdateSampleComponent,
    DuplicateSiteWizardComponent,
    ViewSitesComponent,
    BulkSiteAddComponent,
    DragDropDirective,
    UnauthorizedComponent,
    ViewUsersComponent,
    UserBulkUploadComponent,
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCQwnIPAqsC9USwEKQBaSkMFyWXJ-hJmxE',
      libraries: ['places']
    }),
  ],
  providers: [MDBSpinningPreloader, DatePipe, AuthGuard],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
