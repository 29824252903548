import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {MatTableDataSource} from "@angular/material/table";
import {Site} from "../interfaceModels/siteInterface";
import {Sites} from "../components/view-sites/view-sites.component";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  _siteFields: Array<string> = [];
  set siteFields(value: Array<string>){
    this._siteFields = value;
  }
  get siteFields(): Array<string> {
    return this._siteFields;
  }

  _supplementalFields: Array<string> = [];
  set supplementalFields(value: Array<string>){
    this._supplementalFields = value;
  }
  get supplementalFields(): Array<string> {
    return this._supplementalFields;
  }

  _bulkUploadFields: Array<string> = [];
  set bulkUploadFields(value: Array<string>){
    this._bulkUploadFields = value;
  }
  get bulkUploadFields(): Array<string> {
    return this._bulkUploadFields;
  }

  _dbFields: any = [];
  set dbFields(value: any){
    this._dbFields = value;
  }
  get dbFields(): any {
    return this._dbFields;
  }

  _formStructure: any ;
  set formStructure(value: any){
    this._formStructure = value;
  }
  get formStructure(): any {
    return this._formStructure;
  }

  _currentSites: MatTableDataSource<Sites>;
  set currentSites(value: MatTableDataSource<Sites>){
    this._currentSites = value;
  }
  get currentSites(): MatTableDataSource<Sites> {
    return this._currentSites;
  }

  _customer: string ;
  set customer(value: string){
    this._customer = value;
  }
  get customer(): string {
    return this._customer;
  }

  apiUrl = 'https://fsm-admin-portal-api.azurewebsites.net/api/';
  constructor(private http: HttpClient, private router: Router, private spinner: NgxSpinnerService) { }


  public getDistinctSiteCodes(customer: string): Observable<any> {
    const fullUrl = `${this.apiUrl}GetSites?code=Cs4kcPwk344JV/dVmVqCdPwPU9Ezw31Vi7yq0Thhw80y/Ez2KkWTSg==&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }

  public getDuplicateSiteList(customer: string): Observable<any> {
    const fullUrl = `${this.apiUrl}GetDuplicateSites?code=OIRAaX64qX5L5y2EFaapjUZJKftl9UqKZ00S/uykSn6RmaAhYBNjdQ==&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }

  public getSites(customer: string): Observable<any> {
    const fullUrl = `${this.apiUrl}GetSites?code=Cs4kcPwk344JV/dVmVqCdPwPU9Ezw31Vi7yq0Thhw80y/Ez2KkWTSg==&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }

  public getVisuals(customer: string): Observable<any> {
    const fullUrl = `${this.apiUrl}GetVisulas?code=wPRi/amzjXQR487vofWyZTGa4IDrF8Q0/u5c6uhilH6f0Y43hdPNQw==&customer=${customer}`;
    return this.http.get<any>(fullUrl);
  }

  public updateSite(selectedSite: Site, customer:string){
    return new Promise((resolve, reject) => {
    const fullUrl = `http://localhost:7071/api/UpdateSite?customer=${customer}`;

    return this.http.post<Site>(fullUrl, selectedSite)
      .subscribe(
        res => {
          console.log(res);
          resolve();
        },
        err => {
          console.log('error');
          resolve();
        }
      );
    });
  }

  public bulkAddSites(siteList: any, customer: string){
    const fullUrl = `${this.apiUrl}AddSite?code=wgY3an4LZqjPpQt5iOqATSuVrNSh4PUdD342haUMaacZKIiLCxa/Eg==&customer=${customer}`;
    return this.http.post<any>(fullUrl, siteList)
      .subscribe(
        res => {
          console.log(res);
          this.router.navigate(['/FSM', customer]);
        },
        err => {
          console.log('error');
        }
      );
  }

  public addSite(site: any, customer:string){
    return new Promise((resolve, reject) => {

    const fullUrl = `${this.apiUrl}AddSingleSite?code=jxLT8X5ssrU7Huxv5VGaQC8XBV8JhRXGVqY2N8usHZHrc97HFrEFAw==&customer=${customer}`;
    return this.http.post<any>(fullUrl, site)
      .subscribe(
        res => {
          console.log(res);
          resolve();
        },
        err => {
          console.log('error');
          resolve();
        }
      );
    });
  }
}
