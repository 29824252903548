import { Component, OnInit, AfterViewInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {formatDate} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {EditResourcesApi} from '../../interfaceModels/editResourcesApi';
import {FullOrderInterface} from '../../interfaceModels/fullOrderInterface';
import {DataService} from '../../dataServiceKenyaFMM/data.service';
import {Compartments} from '../../interfaceModels/compartments';



@Component({
  selector: 'app-edit-order',
  templateUrl: './kenya-fmm-edit-order.component.html',
  styleUrls: ['./kenya-fmm-edit-order.component.css']
})
export class KenyaFmmEditOrderComponent implements OnInit{

  // Get order id from header to get resources.
  orderId = this.actRoute.snapshot.paramMap.get('id');

  // Dropdown list vars
  statuses: any [] = [{id: 1, name: 'Completed'}, {id: 2 , name: 'Cancelled'}, {id: 3, name: 'Deleted'}];
  transportTypes: any [] = [{id: 1, name: 'Trailer'}, {id: 2 , name: 'Barge'}, {id: 3, name: 'BulkTank'}, {id: 4, name: 'RailCar'}];
  editResources: EditResourcesApi;
  fullOrder: FullOrderInterface;
  displayedColumns: string[] = ['number', 'productName', 'productType', 'productQuantity',  'markerName', 'markerQuantity', 'editCompartment'];
  isLoadingResults = true;
  orderType = '';

  constructor(private actRoute: ActivatedRoute, public dataService: DataService, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void{

    this.isLoadingResults = true;
    // grab resources for the order
    this.dataService.getEditResources(this.orderId).subscribe((data: EditResourcesApi) => {
      this.editResources = data;


      if (this.fullOrder.TransportTypeId === 3)
      {
        this.editResources.Compartments[0].Number = 1;
      }

      this.dataService._products = data.Products;
    });

    // grab the entire order with all of its info
    this.dataService.getSingleOrder(this.orderId).subscribe((data: FullOrderInterface) => {
      this.fullOrder = data;

      for (const i in this.transportTypes)
      {
        if (this.transportTypes[i].id === this.fullOrder.TransportTypeId)
        {
          this.orderType = 'Edit ' + this.transportTypes[i].name + ' Order';
        }
      }
    });

  }

  updateOrder(){
    // Trailer Number is assigned at the beginning because Ghana uses the same numbers for Trailer and Header for Vehicle Id
    this.dataService.updateOrder(this.fullOrder);
  }

  passCompartment(compartmentEdit: Compartments){
    this.dataService._compartment = compartmentEdit;
  }


}
