<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
<!--Header For Page-->
<div class="top-title-bar" style="position: sticky !important; top: 0px; z-index: 3">
  <h2 class="page-title">{{customer}} FSM User Management</h2>
  <div class="filter-role col-sm text-right p-none">

    <a [routerLink]="['/FSM', this.customer]" style="cursor: pointer;color: white" class="btn btn-defult site-btn">Site Management</a>

    <input type="search" placeholder="Any Keyword" autocomplete="off" name="search"
           (keyup)="applyFilter($event)" class="text-box text-search">

    <a routerLink="bulkUploadUsers" style="cursor: pointer;color: white" class="btn btn-defult site-btn">
      <i class="fa fa-plus"></i> Bulk Upload</a>
  </div>
</div>

<!--Main Body-->
<div class=" col-lg-12 white-box" style="margin: 0px !important; max-height: 850px !important;">

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium"  color="#dd7322" type="ball-spin-clockwise-fade">
  </ngx-spinner>
  <div class="row" >
    <div class="col-lg-6 gray-bg mt-1 mat-elevation-z8"  style="max-height: 850px !important;">
      <div style="overflow-y: scroll; max-height: 850px !important; height: 750px;">
        <table mat-table [dataSource]="dataSource" class="table site-table" matSort matSortActive="FirstName" matSortDirection="asc">

          <!-- Name Column -->
          <ng-container matColumnDef="FirstName">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>First Name</th>
            <td mat-cell *matCellDef="let element" > {{element.FirstName}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="LastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
            <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Username">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Username</th>
            <td mat-cell *matCellDef="let element" > {{element.Username}} </td>
          </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="UserType">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let element" > {{element.UserType.Name}} </td>
          </ng-container>

          <!-- OMC Column -->
          <ng-container matColumnDef="Active">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element">
              <t *ngIf="element.Active">Active</t>
              <t *ngIf="!element.Active">Inactive</t>
            </td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let row">
              <ul class="table-actions" style="align-content: center;">
                <li class="pencil">
                  <i matTooltip="Edit Order" matTooltipPosition="above" class="fa fa-pencil" (click)="populateEditFromTable(row)" ></i>
                </li>
              </ul>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"> ></tr>
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter"</td>
          </tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[15, 25, 35]" fixed showFirstLastButtons></mat-paginator>
    </div>

    <div  class="col-lg-6 gray-bg mt-1 mat-elevation-z8"
          style="width: 100%; max-height: 850px !important; height: 850px; ">

      <div class="gray-box-head" >
        <h2>User Information</h2>
        <h2 style="color: red">Please select a user or add a new one*</h2>
      </div>

      <div class="readony-row" style="max-height: 850px !important;">

        <div class="row" >
          <div class="form-group col-sm-6">
            <div class="form-field-row">
              <label class="form-label">First Name</label>
                <div class="input-field">
                  <input type="text" [(ngModel)]="selectedUser.FirstName">
                </div>
            </div>
          </div>

          <div class="form-group col-sm-6">
            <div class="form-field-row">
              <label class="form-label">Last Name</label>
              <div class="input-field">
                <input type="text" [(ngModel)]="selectedUser.LastName">
              </div>
            </div>
          </div>
        </div>

        <div class="row" >
          <div class="form-group col-sm-6">
            <div class="form-field-row">
              <label class="form-label">Username</label>
              <div class="input-field">
                <input type="text" [(ngModel)]="selectedUser.Username">
              </div>
            </div>
          </div>

          <div class="form-group col-sm-6">
            <div class="form-field-row">
              <label class="form-label">Type</label>
              <div class="select-field">
                <select [(ngModel)]="selectedUser.UserTypeId">
                  <option *ngFor="let type of userTypes" value="{{type.Id}}">{{type.Name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="row" >
          <div class="form-group col-sm-6">
            <div class="form-field-row">
              <label class="form-label">Active</label>
                <mat-checkbox style="margin-left: 10px;" [(ngModel)]="selectedUser.Active"></mat-checkbox>
            </div>
          </div>

          <div class="form-group col-sm-6">
            <div class="form-field-row">
              <label class="form-label">Reset Pin</label>
              <mat-checkbox style="margin-left: 10px;" [(ngModel)]="selectedUser.ResetPin"></mat-checkbox>
            </div>
          </div>
        </div>

      </div>

      <div class=" gray-bg mt-1">
        <div class="gray-box-head">
          <button type="button" class="buttonFixCancel btn-gray " (click)="clearForm()" >Cancel</button>
          <button [hidden]="!userSelected" (click)="updateUser()" type='button'  class=" btn-ornge buttonFix">Update</button>
          <button [disabled]="!allowAdd" [hidden]="userSelected" (click)="addNewUser()" type='button'  class=" btn-ornge buttonFix">Add New</button>
        </div>
      </div>

    </div>
  </div>
</div>
