

<div mdbModal #basicModal="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" (open)="onOpen($event)">
  <div class="modal-dialog modal-notify" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Report Discrepancies Verification</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
          <p>This report was previously flagged for data entry errors upon scanning and processing of records.
          Would you like to mark this record as error free before saving?</p>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">

        <a type="button" class="buttonFixCancel btn-gray waves-light "  mdbWavesEffect (click)="dontVerifySave()"
           data-dismiss="modal">No, thanks</a>

        <a class="waves-effect btn-ornge buttonFix " style=" color: white; margin-left: 50% !important;" mdbWavesEffect (click)="verifySave()">Verify Record
        </a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>


<form ngNativeValidate (ngSubmit)="onSubmit()">

  <!-- As a heading -->
  <div class="authentixBackgroundColor filter-role top-title-bar">
    <a class="adjustments">Edit Report</a>
  </div>
  <div class="gray-bg mt-1 " >
    <ngx-spinner [fullScreen]="true" bdColor="rgba(51,51,51,0.8)" size="medium"  color="#dd7322" type="ball-spin-clockwise-fade">
    </ngx-spinner>
    <div class="gray-bg mt-1">
      <div class="gray-box-head">

        <h2>General Information
          <span *ngIf="editableSample.suspect && editableSample.confidenceLevel < 90" style="color: tomato;">
          <i class="fas fa-flag "></i>
          </span>

          <span *ngIf="editableSample.suspect && editableSample.confidenceLevel >= 90" style="color: #FFC300;">
          <i class="fas fa-flag "></i>
          </span>

        </h2>

      </div>



      <!-- Row 4 -->
      <div class="form-row">

        <div class="form-group col-md-6">
          <label >Report Type</label>
          <input [(ngModel)]="this.editableSample.astmFormId" name="name" class="form-control" required readonly>
        </div>

        <div class="form-group col-md-3">
          <label>Lab Report #</label>
          <input  [(ngModel)]="this.editableSample.labReportNumber" name="labReportNumber" type="text" class="form-control" >
        </div>

        <div class="form-group col-md-3">
          <label>Report Sample Id</label>
          <input  [(ngModel)]="this.editableSample.formSampleId" name="formSampleId" type="text"  class="form-control" >
        </div>

      </div>

      <!-- Row 1 -->
      <div class="form-row">
        <div class="form-group col-md-3">
          <label >Sample</label>
          <input [(ngModel)]="this.editableSample.atxSampleId" name="serial" class="form-control" required>
        </div>

        <div class="form-group col-md-3">
          <label>Report Date</label>
          <input  [(ngModel)]="this.editableSample.reportDate" name="sampleDate" class="form-control" type="date">
        </div>

        <div class="form-group col-md-3">
          <label >Date Received</label>
          <input [(ngModel)]="this.editableSample.dateReceived" name="type" class="form-control" type="date">
        </div>

        <div class="form-group col-md-3">
          <label>Date Sampled</label>
          <input [(ngModel)]="this.editableSample.dateSampled" name="source" class="form-control"  type="date">
        </div>
      </div>

      <!-- Row 2 -->
      <div class="form-row">

        <div class="form-group col-md-3">
          <label >Client</label>
          <input [(ngModel)]="this.editableSample.client" name="description" class="form-control">
        </div>

        <div class="form-group col-md-3">
          <label>Certification Number</label>
          <input  [(ngModel)]="this.editableSample.certificationNumber"  name="reportDate" class="form-control" type="text">
        </div>

        <div class="form-group col-md-3">
          <label>Batch Number</label>
          <input   [(ngModel)]="this.editableSample.batchNumber" name="recievedDate" class="form-control" type="text">
        </div>

        <div class="form-group col-md-3">
          <label>Batch Size</label>
          <input  [(ngModel)]="this.editableSample.batchSize" name="analyzedDate" class="form-control" type="text">
        </div>
      </div>

      <!-- Row 3 -->
      <div class="form-row">
        <div class="form-group col-md-3">
          <label>Tank Number</label>
          <input  [(ngModel)]="this.editableSample.tankNumber" name="tankNumber" type="text" class="form-control" >
        </div>

        <div class="form-group col-md-3">
          <label>Lab Analyst</label>
          <input  [(ngModel)]="this.editableSample.labAnalyst" name="labAnalyst" type="text"  class="form-control" >
        </div>

        <div class="form-group col-md-3">
          <label>Chemist</label>
          <input  [(ngModel)]="this.editableSample.chemist" name="chemist" type="text" class="form-control" >
        </div>

        <div class="form-group col-md-3">
          <label>Chief Chemist</label>
          <input  [(ngModel)]="this.editableSample.chiefChemist" name="chiefChemist" type="text" class="form-control" >
        </div>
      </div>



      <!-- Editable table -->
      <div class="gray-bg mt-1">
        <div class="gray-box-head">
          <h2>Properties</h2>
        </div>
        <div class="card-body ">
          <div id="table" class=" shadow-lg table-editable">
            <table mdbTableScroll class="table table-bordered table-responsive-md table-striped text-center" scrollY="true" maxHeight="320">
              <tr>
                <th style="text-align: left">Analysis</th>
                <th class="text-center">Min Value</th>
                <th class="text-center">Value</th>
                <th class="text-center">Max Value</th>
                <th class="text-center">Test Name</th>
                <th class="text-center">Units</th>
              </tr>
              <tr *ngFor="let property of this.editableSample.formData; let id = index">
                <td style="text-align: left;  padding: 10px">

                  <span contenteditable="false" (keyup)="changeValue(id, 'methodUsed', $event)" (blur)="updateList(id, 'methodUsed', $event)">{{property.testDescription}}</span>
                  <span style="text-align: left;  padding: 10px" [ngClass]="{
            'notSus' : property.suspect === false,
            'verySus' : property.suspect === true
         }">
            <i class="fas fa-flag"></i>
          </span>
                </td>
                <td style="padding: 10px">
                  <span (keyup)="changeValue(id, 'minValue', $event)" (blur)="updateList(id, 'minValue', $event)" contenteditable="false">{{property.minValue}}</span>
                </td>
                <td style="padding: 10px">
                  <span (keyup)="changeValue(id, 'value', $event)" (blur)="updateList(id, 'value', $event)" contenteditable="true">{{property.value}}</span>
                </td>
                <td style="padding: 10px">
                  <span (keyup)="changeValue(id, 'maxValue', $event)" (blur)="updateList(id, 'maxValue', $event)" contenteditable="false">{{property.maxValue}}</span>
                </td>

                <td style="padding:10px" >
                  <span (keyup)="changeValue(id, 'key', $event)" (blur)="updateList(id, 'key', $event)"  contenteditable="false">{{property.testName}}
                    &nbsp;

                  </span>
                </td>
                <td style="padding: 10px">
                  <span contenteditable="false" (keyup)="changeValue(id, 'units', $event)" (blur)="updateList(id, 'units', $event)">{{property.unit}}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
    </div>
    </div>

      <div class="gray-bg mt-1">
        <div class="gray-box-head">
          <button type='submit' value='submit'  class=" btn-ornge buttonFix float-right">Submit</button>
          <button routerLink="/ASTM/Zambia" type="button" class="buttonFixCancel btn-gray ">Cancel</button>
        </div>
      </div>

    </div>
</form>
