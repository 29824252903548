

<form ngNativeValidate (ngSubmit)="onSubmit()">

<!-- As a heading -->
<div class="authentixBackgroundColor filter-role top-title-bar">
  <a class="adjustments">Edit Sample</a>
</div>

<div class="gray-bg mt-1">
<div class="gray-bg mt-1">
  <div class="gray-box-head">
    <h2>General Information</h2>
  </div>


  <!-- Row 1 -->
  <div class="form-row">
    <div class="form-group col-md-3">
      <label >Sample</label>
      <input [(ngModel)]="service.sampleEdit.serial" name="serial" class="form-control" required>
    </div>

    <div class="form-group col-md-3">
      <label >Description</label>
      <input [(ngModel)]="service.sampleEdit.sampleDescription" name="description" class="form-control" required>
    </div>

    <div class="form-group col-md-3">
      <label >Type</label>
      <input [(ngModel)]="service.sampleEdit.sampleType" name="type" class="form-control"  required>
    </div>

    <div class="form-group col-md-3">
      <label>Source</label>
      <input [(ngModel)]="service.sampleEdit.sampleSource" name="source" class="form-control"  required>
    </div>
  </div>

  <!-- Row 2 -->
  <div class="form-row">
    <div class="form-group col-md-3">
      <label>Sample Date</label>
      <input  [(ngModel)]="service.sampleEdit.sampleDate" name="sampleDate" class="form-control" type="date">
    </div>

    <div class="form-group col-md-3">
      <label>Report Date</label>
      <input  [(ngModel)]="service.sampleEdit.reportDate"  name="reportDate" class="form-control" type="date">
    </div>

    <div class="form-group col-md-3">
      <label>Received Date</label>
      <input   [(ngModel)]="service.sampleEdit.sampleReceivedDate" name="recievedDate" class="form-control" type="date">
    </div>

    <div class="form-group col-md-3">
      <label>Analyzed Date</label>
      <input  [(ngModel)]="service.sampleEdit.sampleAnalyzedDate" name="analyzedDate" class="form-control" type="date">
    </div>
  </div>

  <!-- Row 3 -->
  <div class="form-row">
    <div class="form-group col-md-3">
      <label>Location</label>
      <input  [(ngModel)]="service.sampleEdit.sampleLocation" name="location" type="text" class="form-control" >
    </div>

    <div class="form-group col-md-3">
      <label>Client Id</label>
      <input  [(ngModel)]="service.sampleEdit.clientId" name="clientId" type="text"  class="form-control" >
    </div>

    <div class="form-group col-md-3">
      <label>Sampled By</label>
      <input  [(ngModel)]="service.sampleEdit.sampledBy" name="sampledBy" type="text" class="form-control" >
    </div>
  </div>
  </div>

  <!-- Editable table -->
<div class="gray-bg mt-1">
  <div class="gray-box-head">
      <span class="table-add float-right">
        <a class="text-success" (click)="add()">
          <mdb-icon fas icon="plus" size="2x"></mdb-icon>
        </a>
      </span>

    <h2>Properties</h2>

  </div>

    <div class="card-body ">
      <div id="table" class=" shadow-lg table-editable">
        <table mdbTableScroll class="table table-bordered table-responsive-md table-striped text-center" scrollY="true" maxHeight="320">
          <tr>
            <th style="text-align: left">Property</th>
            <th class="text-center">Value</th>
            <th class="text-center">Method Used</th>
            <th class="text-center">Units</th>
            <th class="text-center">Remove</th>
          </tr>
          <tr *ngFor="let property of propertyList; let id = index">
            <td style="text-align: left; padding:10px" >
              <span (keyup)="changeValue(id, 'key', $event)" (blur)="updateList(id, 'key', $event)"  contenteditable="true">{{property.key}}</span>
            </td>
            <td style="padding: 10px">
              <span (keyup)="changeValue(id, 'value', $event)" (blur)="updateList(id, 'value', $event)" contenteditable="true">{{property.value}}</span>
            </td>
            <td style="padding: 10px">
              <span contenteditable="true" (keyup)="changeValue(id, 'methodUsed', $event)" (blur)="updateList(id, 'methodUsed', $event)">{{property.methodUsed}}</span>
            </td>
            <td style="padding: 10px">
              <span contenteditable="true" (keyup)="changeValue(id, 'units', $event)" (blur)="updateList(id, 'units', $event)">{{property.units}}</span>
            </td>
            <td style="padding: 10px">
            <span class="table-remove">
              <button type="button" mdbBtn color="danger" rounded="true" size="sm" class="my-0" style="max-height: 15px; line-height: 0; padding: 10px !important; border-radius: 0.25rem;" (click)="remove(id)">Remove</button>
            </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <!-- Editable table -->


<div class="gray-bg mt-1">
  <div class="gray-box-head">
  <button type='submit' value='submit'  class=" btn-ornge buttonFix float-right">Submit</button>
  <button routerLink="/ASTM/MOE" type="button" class="buttonFixCancel btn-gray ">Cancel</button>
  </div>
</div>
</div>
</form>
