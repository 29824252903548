import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../dataServiceMoeKsaASTM/data.service';
import {PropertyListDefault} from '../../interfaceModels/propertyListDefault';
import {SingleSampleInterfaceModel} from '../../interfaceModels/singleSampleInterface.model';

@Component({
  selector: 'app-update-sample',
  templateUrl: './moe-ksa-astm-update-sample.component.html',
  styleUrls: ['./moe-ksa-astm-update-sample.component.scss']
})
export class MoeKsaAstmUpdateSampleComponent implements OnInit {

  constructor(public service: DataService, public  router: Router) { }

  editField: string;
  propertyList: any = [];
  tempProperty: PropertyListDefault;
  sample: SingleSampleInterfaceModel;

  register(form) {

    // create sample first
    console.log(form.value);

  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.propertyList[id][property] = editField;
  }

  remove(id: any) {

    const taken = this.propertyList.splice(id, 1);
    this.service.deleteProperty(taken[0].id).subscribe();
  }

  add() {

    const newProperty = {id: 0, parentId: this.service.sampleEdit.id, key: 'New Property', value: '0', methodUsed: 'ASTM', units: '--'};
    this.propertyList.unshift(newProperty);

  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }

  onSubmit() {
    this.service._sampleEdit.sampleProperties = this.propertyList;
    console.log(this.service.sampleEdit);
    this.service.updateSample(this.service.sampleEdit);

  }

  ngOnInit(): void {
    const noDoubles: any = [];

    this.service.getSampleProperties(this.service.sampleEdit.id).subscribe((data: PropertyListDefault) => {
      this.propertyList = data;
    });
  }
}
