


<!-- POP UP FOR LIST SELECTION -->
<div mdbModal #basicModal="mdbModal" class="modal right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     [config]='{backdrop: true, ignoreBackdropClick: true}'   aria-hidden="true" style="height: 100% !important;">
  <div class="modal-dialog"  role="document">
    <div class="modal-content" >
      <div class="modal-header">
        <h4 class="adjustments" style="padding-top: 3% !important;">Please select a report type from the menu.</h4>
      </div>
<div>
      <mat-form-field appearance="fill" style=" width: 80% !important; margin-left: 10% !important; margin-top: 10%;">
        <mat-select placeholder="Report Types" [(ngModel)]="matchingProperties">
          <mat-option *ngFor="let options of optionsSelect" [value]="options">
            {{options.Name}}
            </mat-option>
            </mat-select>
      </mat-form-field>

</div>
      <div class="modal-footer " style="padding-top: 10%">

        <button routerLink="/ASTM/Zambia" type="button" class="buttonFixCancel btn-gray">Cancel</button>

        <button   class=" btn-ornge buttonFix" style="margin-left: 73% !important;" (click)="closeModal()" >Ok</button>
      </div>
    </div>
  </div >>
</div >

<!-- Main Heading and Content -->

<!-- As a heading -->
<div class="authentixBackgroundColor filter-role top-title-bar">
  <a class="adjustments">Add Report</a>
</div>

<form #myform="ngForm" ngNativeValidate (ngSubmit)="register(myform)">

  <div class="gray-bg mt-1">
    <div class="gray-bg mt-1">
      <div class="gray-box-head">
        <h2>General Information</h2>
      </div>



      <!-- Row 1 -->
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sample-astm-type" >Report Type</label>
          <input id="sample-astm-type" value="{{chosenType}}" name="astmFormId" class="form-control" ngModel readonly>
        </div>

        <div class="form-group col-md-3">
          <label for="labReportNumber">Lab Report #</label>
          <input name="labReportNumber" id="labReportNumber"  class="form-control" ngModel >
        </div>

        <div class="form-group col-md-3">
          <label for="formSampleId">Report Sample Id</label>
          <input name="formSampleId" id="formSampleId"  class="form-control" ngModel>
        </div>
      </div>


      <!-- Row 2 -->
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="atxSampleId">Sample</label>
          <input name="atxSampleId" id="atxSampleId"   class="form-control" ngModel required>
        </div>

        <div class="form-group col-md-3">
          <label>Report Date</label>
          <input  name="reportDate" ngModel class="form-control" type="date">
        </div>

        <div class="form-group col-md-3">
          <label>Date Received</label>
          <input  name="dateReceived" ngModel class="form-control" type="date">
        </div>


        <div class="form-group col-md-3">
          <label>Date Sampled</label>
          <input  name="dateSampled" ngModel class="form-control" type="date">
        </div>



        <div class="form-group col-md-3">
          <label for="sample-source">Certification Number</label>
          <input name="certificationNumber" id="sample-source"  class="form-control" ngModel>
        </div>

        <div class="form-group col-md-3">
          <label for="client">Client</label>
          <input name="client" id="client"  class="form-control" ngModel>
        </div>

        <div class="form-group col-md-3">
          <label>Batch Number</label>
          <input   name="batchNumber" ngModel class="form-control">
        </div>

        <div class="form-group col-md-3">
          <label>Batch Size</label>
          <input  name="batchSize" ngModel class="form-control">
        </div>
      </div>

      <!-- Row 3 -->
      <div class="form-row">
        <div class="form-group col-md-3">
          <label>Tank Number</label>
          <input  name="tankNumber" type="text" ngModel class="form-control" >
        </div>

        <div class="form-group col-md-3">
          <label>Lab Analyst</label>
          <input  name="labAnalyst" type="text" ngModel class="form-control" >
        </div>

        <div class="form-group col-md-3">
          <label>Chemist</label>
          <input  name="chemist" ngModel class="form-control" >
        </div>

        <div class="form-group col-md-3">
          <label>Cheif Chemist</label>
          <input  name="chiefChemist" ngModel class="form-control" >
        </div>

      </div>
    </div>

    <!-- Editable table -->
    <div class="gray-bg mt-1">
      <div class="gray-box-head">
        <h2>Properties</h2>
      </div>
      <div class="card-body ">
        <div id="table" class=" shadow-lg table-editable">
          <table mdbTableScroll class="table table-bordered table-responsive-md table-striped text-center" scrollY="true" maxHeight="320">
            <tr>
              <th style="text-align: left">Analysis</th>
              <th class="text-center">Min Value</th>
              <th class="text-center">Value</th>
              <th class="text-center">Max Value</th>
              <th class="text-center">Test Name</th>
              <th class="text-center">Units</th>
            </tr>
            <tr *ngFor="let property of this.editableSample.formData; let id = index">
              <td style="text-align: left; padding:10px" >
                <span contenteditable="false" (keyup)="changeValue(id, 'methodUsed', $event)" (blur)="updateList(id, 'methodUsed', $event)">{{property.testDescription}}</span>
              </td>
              <td style="padding: 10px">
                <span (keyup)="changeValue(id, 'minValue', $event)" (blur)="updateList(id, 'minValue', $event)" contenteditable="false">{{property.minValue}}</span>
              </td>
              <td style="padding: 10px">
                <span (keyup)="changeValue(id, 'value', $event)" (blur)="updateList(id, 'value', $event)" contenteditable="true">{{property.Value}}</span>
              </td>
              <td style="padding: 10px">
                <span (keyup)="changeValue(id, 'maxValue', $event)" (blur)="updateList(id, 'maxValue', $event)" contenteditable="false">{{property.maxValue}}</span>
              </td>
              <td style="padding: 10px">
                <span (keyup)="changeValue(id, 'key', $event)" (blur)="updateList(id, 'key', $event)"  contenteditable="false">{{property.testName}}</span>
                              </td>
              <td style="padding: 10px">
                <span contenteditable="false" (keyup)="changeValue(id, 'units', $event)" (blur)="updateList(id, 'units', $event)">{{property.unit}}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- Editable table -->

    <div class="gray-bg mt-1">
      <div class="gray-box-head">
        <button type="submit" value="Register" class="btn-ornge buttonFix float-right">Submit</button>
        <button routerLink="/ASTM/Zambia" class=" btn-gray buttonFixCancel">Cancel</button>
      </div>
    </div>

  </div>

</form>
