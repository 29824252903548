import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../dataServiceKsaASTM/data.service';
import {ModalDirective} from 'ng-uikit-pro-standard';
import {ListObjects} from '../../interfaceModels/listObjects';

@Component({
  selector: 'app-add-sample',
  templateUrl: './ksa-astm-add-sample.component.html',
  styleUrls: ['./ksa-astm-add-sample.component.scss']
})
export class KsaAstmAddSampleComponent implements OnInit, AfterViewInit {

  constructor(public dataService: DataService) { }

  @ViewChild('basicModal') public showModalOnClick: ModalDirective;

  optionsSelect: string [];
  chosenType: any;
  matchingProperties: any;
  editField: string;
  editableSample: ListObjects;

  public showModal(): void {

    this.showModalOnClick.show();

  }

  public closeModal(): void {
    this.showModalOnClick.hide();
    this.chosenType = this.matchingProperties.Name;

    for (let i = 0; i < this.matchingProperties.ConfigSeries[0].ConfigColumn[1].ColumnRowInfo.length; i++) {
      // @ts-ignore
      this.editableSample.formData.push(
        {
          seriesName: this.matchingProperties.ConfigSeries[0].Name,
          testName: this.matchingProperties.ConfigSeries[0].ConfigColumn[1].ColumnRowInfo[i].ExpectedValue,
          formId: 0,
          id: 0,
          maxValue: null, //this.matchingProperties.ConfigSeries[0].ConfigColumn[3].ColumnRowInfo[i].ExpectedValue,
          minValue: null, //this.matchingProperties.ConfigSeries[0].ConfigColumn[2].ColumnRowInfo[i].ExpectedValue,
          rowNumber: this.matchingProperties.ConfigSeries[0].ConfigColumn[1].ColumnRowInfo.length - i,
          testDescription: this.matchingProperties.ConfigSeries[0].ConfigColumn[0].ColumnRowInfo[i].ExpectedValue,
          unit: this.matchingProperties.ConfigSeries[0].SeriesRowInfo[i].Unit
        }
      );
    }

    this.editableSample.formData = this.editableSample.formData.reverse();
  }


  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.editableSample.formData[id][property] = editField;
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }

  register(form) {
    form.value.astmFormId = this.chosenType;
    form.value.formConfigId = this.matchingProperties.ConfigSeries[0].FormConfigId;
    form.value.formData = this.editableSample.formData;

    console.log(form.value.formData);

    this.dataService.addSample(form.value);
  }

  ngOnInit(): void {
    this.editableSample = {
      readInfo: '',
      successful: true,
      astmFormId: '',
      formConfigId: null,
      confidenceLevel: null,
      formSampleId: '',
      atxSampleId: '',
      dateSampled: '',
      reportDate: '',
      client: '',
      certificationNumber: '',
      batchNumber: '',
      batchSize: '',
      tankNumber: '',
      labReportNumber: '',
      labAnalyst: '',
      chemist: '',
      chiefChemist: '',
      suspect: false,
      suspectReason: null,
      deleted: false,
      fileName: null,
      createdOn: null,
      id: null,
      fileSourceId: null,
      page: null,
      dateReceived: null,
      exception: null,
      formData: []};

    this.optionsSelect = this.dataService.dropDowns;


  }



  ngAfterViewInit(): void {
    this.showModal();
  }

}
