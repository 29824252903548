<div class="top-title-bar">
  <a class="adjustments">{{this.orderType}}</a>
</div>

<ngx-spinner [fullScreen]="true" bdColor="rgba(51,51,51,0.8)" size="medium" color="#dd7322"
  type="ball-spin-clockwise-fade">
</ngx-spinner>

<div class="gray-bg mt-1">
  <div class="gray-bg mt-1">
    <div class="gray-box-head">
      <h2>General Information</h2>
    </div>
    <div>

      <div class="row">
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Order ID</label>
            <div class="input-field">
              <input [(ngModel)]="this.dataService.foreignKeys.OrderUniqueId" readonly type="text">
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-field-row">
            <label class="form-label">Fan/Order Number</label>
            <div class="input-field">
              <input [(ngModel)]="this.dataService.foreignKeys.OrderNumber" readonly type="text">
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-field-row">
            <label class="form-label">Terminal</label>
            <div class="input-field">
              <input [(ngModel)]="this.dataService.foreignKeys.TerminalName" readonly type="text">
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label>Order Date</label>
          <mat-form-field style="width: 100% !important;">
            <input [(ngModel)]="fullOrder.OrderDate" [matDatepicker]="picker1" matInput>
            <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <label>Processed Date</label>
          <mat-form-field style="width: 100% !important;">
            <input [(ngModel)]="fullOrder.OrderProcDate" [matDatepicker]="picker2" matInput>
            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-offset-1 col-md-2" [ngClass]="{'collapse in' : fullOrder.TransportTypeId != 1}">
          <div class="form-field-row">
            <label class="form-label">Terminal Tag Number</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.TerminalTagNumber" type="text">
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label class="form-label">Destination</label>
          <ng-select [(ngModel)]="fullOrder.Destination" [items]="this.dataService.dropDowns.desLocations"
            [virtualScroll]="true" bindLabel="name" bindValue="name" placeholder="Select city">
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Status</label>
          <ng-select [(ngModel)]="fullOrder.OrderStatusId" [items]="statuses" [virtualScroll]="true" bindLabel="name"
            bindValue="id" placeholder="Select Status">
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Shipper</label>
          <ng-select [(ngModel)]="fullOrder.MarketingCompanyId" [items]="editResources.OmcList" [virtualScroll]="true"
            bindLabel="Name" bindValue="Id" placeholder="Select Shipper">
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Importer</label>
          <ng-select [(ngModel)]="fullOrder.DistributionCompanyId" [items]="editResources.BdcList"
            [virtualScroll]="true" bindLabel="Name" bindValue="Id" placeholder="Select Importer">
          </ng-select>
        </div>
      </div>

      <div class="row" [ngClass]="{'collapse in' : fullOrder.TransportTypeId != 1}">
        <div class="col-md-2">
          <label>Transporter</label>
          <ng-select [(ngModel)]="fullOrder.TransporterId" [items]="this.dataService.dropDowns.transporters"
            [virtualScroll]="true" bindLabel="name" bindValue="id" placeholder="Select Transporter">
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Vehicle Id #</label>
          <ng-select [(ngModel)]="fullOrder.TankerHeadNumber" [items]="dataService.dropDowns.tankerHeadId"
            [virtualScroll]="true" bindLabel="name" bindValue="name" placeholder="Select Vehicle Id">
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Trailer #</label>
          <ng-select [(ngModel)]="fullOrder.TrailerNumber" [items]="dataService.dropDowns.trailerId"
            [virtualScroll]="true" bindLabel="name" bindValue="name" placeholder="Select Trailer Id">
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Driver</label>
          <ng-select [(ngModel)]="fullOrder.DriverName" [items]="dataService.dropDowns.driverName"
            [virtualScroll]="true" bindLabel="name" bindValue="name" placeholder="Select Driver">
          </ng-select>
        </div>
        <div class="col-md-2">
          <label>Customer</label>
          <ng-select [(ngModel)]="fullOrder.CustomerId" [items]="dataService.dropDowns.customers" [virtualScroll]="true"
            bindLabel="name" bindValue="id" placeholder="Select Customer">
          </ng-select>
        </div>
      </div>

      <div class="row" [ngClass]="{'collapse in' : fullOrder.TransportTypeId != 3}">
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Shore Tank Number</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.BulkTankIdentification" type="text">
            </div>
          </div>
        </div>
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Bulk Tank Volume</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.BulkTankVolume" type="text">
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label>Customer</label>
          <ng-select [(ngModel)]="fullOrder.CustomerId" [items]="dataService.dropDowns.customers" [virtualScroll]="true"
            bindLabel="name" bindValue="id" placeholder="Select Customer">
          </ng-select>
        </div>
      </div>

      <div class="row" [ngClass]="{'collapse in' : fullOrder.TransportTypeId != 2}" >
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Vessel Name</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.VesselIdentification" type="text">
            </div>
          </div>
        </div>
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Vessel Captain</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.VesselCaptainName" type="text">
            </div>
          </div>
        </div>
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Owner</label>
            <ng-select [(ngModel)]="fullOrder.VesselOperatorCompanyId" [items]="dataService.dropDowns.vesselOperatorCompanies" [virtualScroll]="true"
            bindLabel="name" bindValue="id" placeholder="Select Owner">
          </ng-select>
          </div>
        </div>
      </div>

      <div class="row" [ngClass]="{'collapse in' : fullOrder.TransportTypeId != 4}">
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Registration #</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.CarRegistrationNumber" type="text">
            </div>
          </div>
        </div>
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Number of Wagons</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.NumTrailers" type="text">
            </div>
          </div>
        </div>
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Conductor</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.ConductorName" type="text">
            </div>
          </div>
        </div>
        <div class="col-md-offset-1 col-md-2">
          <div class="form-field-row">
            <label class="form-label">Owner</label>
            <div class="input-field">
              <input [(ngModel)]="fullOrder.OwnerName" type="text">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="gray-bg mt-1">
  <div class="gray-box-head">
    <h2>Compartments</h2>
  </div>

  <div class="white-box">
    <div class="example-container-compartment mat-elevation-z8">
      <ngx-spinner [fullScreen]="true" bdColor="rgba(51,51,51,0.8)" size="medium" color="#dd7322"
        type="ball-spin-clockwise-fade">
      </ngx-spinner>
      <div class="table-responsive">

        <table mat-table [dataSource]="editResources.Compartments" class="table site-table">

          <!-- Number Column -->
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>Number</th>
            <td mat-cell *matCellDef="let row">{{row.Number}}</td>
          </ng-container>

          <!-- Product Name Column -->
          <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef>Product Name</th>
            <td mat-cell *matCellDef="let row">{{row.ProductName}}</td>
          </ng-container>

          <!-- Product Type Column -->
          <ng-container matColumnDef="productType">
            <th mat-header-cell *matHeaderCellDef>Product Type</th>
            <td mat-cell *matCellDef="let row">{{row.ProductType}}</td>
          </ng-container>

          <!-- Product Quantity Column -->
          <ng-container matColumnDef="productQuantity">
            <th mat-header-cell *matHeaderCellDef>Product Quantity</th>
            <td mat-cell *matCellDef="let row">{{row.ProductQuantity}}</td>
          </ng-container>

          <!-- Marker Name Column -->
          <ng-container matColumnDef="markerName">
            <th mat-header-cell *matHeaderCellDef>Marker Name</th>
            <td mat-cell *matCellDef="let row">{{row.MarkerName}}</td>
          </ng-container>

          <!-- Marker Quantity Column -->
          <ng-container matColumnDef="markerQuantity">
            <th mat-header-cell *matHeaderCellDef>Marker Quantity</th>
            <td mat-cell *matCellDef="let row">{{row.MarkerQuantity}}</td>
          </ng-container>


          <!-- Edit Record Column-->
          <ng-container matColumnDef="editCompartment">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let row">
              <ul class="table-actions">
                <li class="pencil" routerLink="editCompartment" (click)="passCompartment(row)">
                  <i matTooltip="Edit Compartment" matTooltipPosition="above" class="fa fa-pencil"></i></li>
              </ul>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

    </div>
  </div>
</div>




<div class="gray-bg mt-1">
  <div class="gray-box-head">
    <input _ngcontent-c18="" class="btn btn-gray" routerLink="/FMM/Kenya" type="button" value="Cancel">
    <input (click)="updateOrder()" class="btn btn-ornge float-right" type="button" value="Update">
  </div>
</div>
