export class SitesSupplementalInfo {
  Key = ' ';
  Value = ' ';
}

export class AddSiteInterface {
  Name = ' ';
  Code = ' ';
  Address = ' ';
  City = ' ';
  County = ' ';
  State = ' ';
  Country = ' ';
  ZipCode = ' ';
  Phone = ' ';
  Owner = ' ';
  OilMarketingCompany = ' ';
  Type = ' ';
  SitesSupplementalInfo: SitesSupplementalInfo[];
}
