<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<div class="top-title-bar">
  <h2 class="page-title"> {{this.customer}} FSM Duplicate Site Consolidation</h2>
  <div class="filter-role col-sm text-right p-none">
  <a [routerLink]="['/FSM', this.customer]" style="cursor: pointer;color: white" class="btn btn-defult site-btn">Site Management</a>
  </div>
</div>

  <div class="row justify-content-center" style="margin: 0px;" >
<div class="col-lg-3 gray-bg mt-1">
  <div class="gray-bg mt-1">
  <div >
    <table mat-table [dataSource]="dataSource" class="table site-table">

      <!-- Name Column -->
      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef  >Site Code</th>
        <td mat-cell *matCellDef="let element" > {{element.code}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="Count">
        <th mat-header-cell *matHeaderCellDef >Count</th>
        <td  mat-cell *matCellDef="let element"> {{element.count}} </td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td mat-cell *matCellDef="let row">
          <ul class="table-actions">
            <li class="pencil">
              <i matTooltip="View Duplicates" (click)="getRelatedDuplicates(row.code)" matTooltipPosition="above" class="fa fa-search"></i>
            </li>
          </ul>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"> ></tr>
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter"</td>
      </tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[15, 25, 35]" fixed showFirstLastButtons></mat-paginator>
</div>
</div>

    <div class="col-lg-9 gray-bg mt-1">

      <div class="text-center gray-bg mt-1" style=" height: 100%" [hidden]="!showGreeting">
        <BR>
        <BR>
        <BR>
        <BR>
        <span style="color: #f88d3d">
            <i class="far fa-question-circle fa-10x"></i>
        </span>
        <BR>
        <BR>
        <BR>
        <h4>Please select a site code from the table that you would like to validate.</h4>
        <BR>
        <h4>After selection you will be presented with all duplicate sites for that code and asked to validate the true site.</h4>
      </div>

      <div class="text-center gray-bg mt-1" style=" height: 100%" [hidden]="!showConfirmation">
        <BR>
        <BR>
        <BR>
        <BR>
        <span style="color: #f88d3d">
            <i class="far fa-check-circle fa-10x"></i>
        </span>
        <BR>
        <BR>
        <BR>
        <h4>Site "{{siteCode}}" has been verified and the other sites have been consolidated.</h4>
        <BR>
        <h4>Please select a new site to validate.</h4>

      </div>


        <div [hidden]="!siteSelected" class="gray-bg mt-1">
          <div  class="gray-box-head">
            <h2>Please select the site with the correct information.</h2>
            <h2>Header Information : (Site Code) - (Last Date Updated) - (Geo Tag Included)</h2>
          </div>




        <div class="row  justify-content-center" style="margin: 10px;">
          <div *ngFor="let site of duplicateSiteGroup" class="card mat-elevation-z8" style="margin: 20px; border: 1px solid lightgrey;">
            <div class="card-header" style="background: #f88d3d; color: white;">
              {{site.Code}} &nbsp; - &nbsp; {{site.CreatedAtUtc | date}}
              <span *ngIf="site.GeoLocation != null">
                 &nbsp; - &nbsp;
              <i class="fas fa-map-marker-alt"></i>
              </span>
              <mat-radio-button (click)="setSite(site.SiteId)" class="float-right" ></mat-radio-button>
            </div>
              <div class="card-body width-auto row">

                <div class="col-sm-7">
                <h3>Site</h3>
                <div class="gray-bg mt-1">
                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">Name:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.Name}}</h6>
                </div>

                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">Address:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.Address}}</h6>
                </div>

                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">City:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.City}}</h6>
                </div>

                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">County:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.County}}</h6>
                </div>

                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">State:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.State}}</h6>
                </div>

                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">Phone:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.Phone}}</h6>
                </div>

                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">Type:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.Type}}</h6>
                </div>

                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">OMC:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.OilMarketingCompany}}</h6>
                </div>

                <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">Owner:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{site.Owner}}</h6>
                </div>

                <div *ngFor="let supp of site.sitesSupplementalInfo" class="row" style="margin-left: 0px; margin-bottom: 2px;">
                  <h6 class="card-subtitle mb-2">{{supp.Key}}:</h6>
                  &nbsp;
                  <h6 class="card-subtitle mb-2 text-muted"> {{supp.Value}}</h6>
                </div>
</div>
                </div>
                <div class="col-sm-5">
                  <h3>Containers</h3>
                  <div *ngFor="let container of site.Containers" class="gray-bg mt-1">

                    <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                    <h6 class="card-subtitle mb-2">Name:</h6>
                    &nbsp;
                    <h6 class="card-subtitle mb-2 text-muted"> {{container.Name}}</h6>
                    </div>

                    <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                      <h6 class="card-subtitle mb-2">Product:</h6>
                      &nbsp;
                      <h6 class="card-subtitle mb-2 text-muted"> {{container.Product}}</h6>
                    </div>

                    <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                    <h6 class="card-subtitle mb-2">Capacity:</h6>
                    &nbsp;
                    <h6 class="card-subtitle mb-2 text-muted"> {{container.Capacity}}</h6>
                    </div>

                    <div class="row" style="margin-left: 0px; margin-bottom: 2px;">
                      <h6 class="card-subtitle mb-2">Type:</h6>
                      &nbsp;
                      <h6 class="card-subtitle mb-2 text-muted"> {{container.Type}}</h6>
                    </div>

                  </div>
                </div>

              </div>
          </div>
        </div>
        </div>

      <div class="gray-bg mt-1" [hidden]="!siteSelected">
        <div class="gray-box-head">
          <input class="btn btn-gray" type="button" value="Cancel" (click)="cancelProcess()" >
          <input  class="btn btn-ornge float-right" type="button" value="Validate" (click)="validateSite()">
        </div>
      </div>


    </div>
    </div>


